import { IconSVGProps } from '@/components/ui/icon'

function PunchCard({ size = 16 }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8346 12.3901L9.57715 10.1326C9.49465 10.0501 9.39715 10.0351 9.34465 10.0351C9.29215 10.0351 9.19465 10.0501 9.11215 10.1326L6.84715 12.3976C6.59215 12.6526 6.19465 13.0651 4.86715 13.0651L7.64965 15.8401C8.52715 16.7176 9.95215 16.7176 10.8296 15.8401L13.6196 13.0576C12.9371 13.0576 12.3671 12.9226 11.8346 12.3901Z"
        fill="currentcolor"
      />
      <path
        d="M6.84715 5.60258L9.11215 7.86758C9.17215 7.92758 9.26215 7.96508 9.34465 7.96508C9.42715 7.96508 9.51715 7.92758 9.57715 7.86758L11.8196 5.62508C12.3521 5.07008 12.9596 4.94258 13.6421 4.94258L10.8521 2.16008C9.97465 1.28258 8.54965 1.28258 7.67215 2.16008L4.88965 4.93508C6.20965 4.93508 6.61465 5.37008 6.84715 5.60258Z"
        fill="currentcolor"
      />
      <path
        d="M16.0796 7.38758L14.3921 5.69258H13.4471C13.0421 5.69258 12.6371 5.85758 12.3596 6.15008L10.1096 8.40008C9.89965 8.61008 9.62215 8.71508 9.34465 8.71508C9.07465 8.71508 8.78965 8.60258 8.57965 8.40008L6.31465 6.12758C6.02965 5.84258 5.63965 5.67758 5.22715 5.67758H4.12465L2.40715 7.40258C1.52965 8.28008 1.52965 9.70508 2.40715 10.5826L4.12465 12.3076H5.23465C5.63965 12.3076 6.02965 12.1426 6.32215 11.8576L8.58715 9.59258C8.79715 9.38258 9.07465 9.27758 9.35215 9.27758C9.62965 9.27758 9.90715 9.38258 10.1171 9.59258L12.3746 11.8501C12.6596 12.1351 13.0496 12.3001 13.4621 12.3001H14.4071L16.0946 10.6051C16.9721 9.72008 16.9721 8.28008 16.0796 7.38758Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default PunchCard
