import { IconSVGProps } from '@/components/ui/icon'

function CheckFile({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9403 4.94016L9.72032 1.72016C9.47366 1.4735 9.13366 1.3335 8.78033 1.3335H4.00033C3.26699 1.3335 2.67366 1.9335 2.67366 2.66683L2.66699 13.3335C2.66699 14.0668 3.26033 14.6668 3.99366 14.6668H12.0003C12.7337 14.6668 13.3337 14.0668 13.3337 13.3335V5.88683C13.3337 5.5335 13.1937 5.1935 12.9403 4.94016ZM6.82032 11.5268L5.40699 10.1135C5.14699 9.8535 5.14699 9.4335 5.40699 9.1735C5.66699 8.9135 6.08699 8.9135 6.34699 9.1735L7.28699 10.1135L9.64699 7.7535C9.90699 7.4935 10.327 7.4935 10.587 7.7535C10.847 8.0135 10.847 8.4335 10.587 8.6935L7.76033 11.5202C7.50699 11.7868 7.08033 11.7868 6.82032 11.5268ZM9.33366 6.00016C8.96699 6.00016 8.66699 5.70016 8.66699 5.3335V2.3335L12.3337 6.00016H9.33366Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default CheckFile
