import { IconSVGProps } from '@/components/ui/icon'

function Subject({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 12.75H4.25C3.8375 12.75 3.5 13.0875 3.5 13.5C3.5 13.9125 3.8375 14.25 4.25 14.25H10.25C10.6625 14.25 11 13.9125 11 13.5C11 13.0875 10.6625 12.75 10.25 12.75ZM14.75 6.75H4.25C3.8375 6.75 3.5 7.0875 3.5 7.5C3.5 7.9125 3.8375 8.25 4.25 8.25H14.75C15.1625 8.25 15.5 7.9125 15.5 7.5C15.5 7.0875 15.1625 6.75 14.75 6.75ZM4.25 11.25H14.75C15.1625 11.25 15.5 10.9125 15.5 10.5C15.5 10.0875 15.1625 9.75 14.75 9.75H4.25C3.8375 9.75 3.5 10.0875 3.5 10.5C3.5 10.9125 3.8375 11.25 4.25 11.25ZM3.5 4.5C3.5 4.9125 3.8375 5.25 4.25 5.25H14.75C15.1625 5.25 15.5 4.9125 15.5 4.5C15.5 4.0875 15.1625 3.75 14.75 3.75H4.25C3.8375 3.75 3.5 4.0875 3.5 4.5Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Subject
