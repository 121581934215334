import { IconSVGProps } from '@/components/ui/icon'

function PeapleGroup({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 120 119"
      fill="none"
    >
      <path
        opacity="0.1"
        d="M59.7592 103.69C84.3981 103.69 104.372 83.6496 104.372 58.929C104.372 34.2083 84.3981 14.1682 59.7592 14.1682C35.1203 14.1682 15.1465 34.2083 15.1465 58.929C15.1465 83.6496 35.1203 103.69 59.7592 103.69Z"
        fill="currentcolor"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.6112 55.4013C57.6726 55.4013 56.133 56.954 56.133 58.8302C56.133 60.7063 57.6726 62.2591 59.6112 62.2591C61.5498 62.2591 63.0894 60.7064 63.0894 58.8302C63.0894 56.954 61.5498 55.4013 59.6112 55.4013ZM51.9363 58.8302C51.9363 54.6011 55.3901 51.2046 59.6112 51.2046C63.8324 51.2046 67.2861 54.6012 67.2861 58.8302C67.2861 63.0592 63.8324 66.4558 59.6112 66.4558C55.3901 66.4558 51.9363 63.0592 51.9363 58.8302Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.7589 17.4682C36.9559 17.4682 18.4955 35.8993 18.4955 58.5836H11.501C11.501 32.0162 33.113 10.4736 59.7589 10.4736V17.4682Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.759 99.6992C82.5621 99.6992 101.023 81.268 101.023 58.5837H108.017C108.017 85.1511 86.405 106.694 59.759 106.694V99.6992Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.2033 28.8337C77.2729 28.8337 75.708 30.3986 75.708 32.329C75.708 34.2595 77.2729 35.8244 79.2033 35.8244C81.1337 35.8244 82.6986 34.2595 82.6986 32.329C82.6986 30.3986 81.1337 28.8337 79.2033 28.8337ZM74.3091 32.329C74.3091 29.626 76.5003 27.4348 79.2033 27.4348C81.9063 27.4348 84.0975 29.626 84.0975 32.329C84.0975 35.032 81.9063 37.2233 79.2033 37.2233C76.5003 37.2233 74.3091 35.032 74.3091 32.329Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7718 40.0361C75.0984 40.0361 72.8947 42.2398 72.8947 44.9132V56.4612C72.8947 57.4165 73.6764 58.2293 74.6628 58.2293H83.6939C84.6718 58.2293 85.469 57.4226 85.5113 56.4472V44.9132C85.5113 42.2398 83.3076 40.0361 80.6342 40.0361H77.7718ZM71.4958 44.9132C71.4958 41.4672 74.3258 38.6372 77.7718 38.6372H80.6342C84.0802 38.6372 86.9102 41.4672 86.9102 44.9132V56.4612C86.9102 56.4698 86.91 56.4785 86.9097 56.4871C86.8473 58.1713 85.4757 59.6282 83.6939 59.6282H74.6628C72.8856 59.6282 71.4958 58.1708 71.4958 56.4612V44.9132Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.019 59.6284C38.0886 59.6284 36.5236 61.1933 36.5236 63.1237C36.5236 65.0541 38.0886 66.619 40.019 66.619C41.9494 66.619 43.5143 65.0541 43.5143 63.1237C43.5143 61.1933 41.9494 59.6284 40.019 59.6284ZM35.1248 63.1237C35.1248 60.4207 37.316 58.2295 40.019 58.2295C42.722 58.2295 44.9132 60.4207 44.9132 63.1237C44.9132 65.8267 42.722 68.0179 40.019 68.0179C37.316 68.0179 35.1248 65.8267 35.1248 63.1237Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5875 70.831C35.9141 70.831 33.7104 73.0347 33.7104 75.7082V87.2561C33.7104 88.2114 34.492 89.0242 35.4785 89.0242H44.5096C45.5141 89.0242 46.327 88.1933 46.327 87.2561V75.7082C46.327 73.0347 44.1233 70.831 41.4499 70.831H38.5875ZM32.3115 75.7082C32.3115 72.2622 35.1415 69.4321 38.5875 69.4321H41.4499C44.8959 69.4321 47.7259 72.2621 47.7259 75.7082V87.2561C47.7259 88.9838 46.2686 90.4231 44.5096 90.4231H35.4785C33.7013 90.4231 32.3115 88.9658 32.3115 87.2561V75.7082Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64768 58.2295C4.03398 58.2295 4.34713 58.5426 4.34713 58.9289C4.34713 89.4359 29.104 114.193 59.611 114.193C59.9973 114.193 60.3104 114.506 60.3104 114.892C60.3104 115.278 59.9973 115.592 59.611 115.592C28.3314 115.592 2.94824 90.2085 2.94824 58.9289C2.94824 58.5426 3.26139 58.2295 3.64768 58.2295Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.9119 2.9658C58.9119 2.57951 59.225 2.26636 59.6113 2.26636C90.8909 2.26636 116.274 27.6495 116.274 58.9291C116.274 59.3154 115.961 59.6285 115.575 59.6285C115.188 59.6285 114.875 59.3154 114.875 58.9291C114.875 28.4221 90.1183 3.66524 59.6113 3.66524C59.225 3.66524 58.9119 3.35209 58.9119 2.9658Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default PeapleGroup
