import { IconSVGProps } from '@/components/ui/icon'

function Slides({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 1.66663H3.33333C2.41666 1.66663 1.66666 2.41663 1.66666 3.33329V10.8333C1.66666 11.2916 2.04166 11.6666 2.5 11.6666C2.95833 11.6666 3.33333 11.2916 3.33333 10.8333V3.33329H10.8333C11.2917 3.33329 11.6667 2.95829 11.6667 2.49996C11.6667 2.04163 11.2917 1.66663 10.8333 1.66663ZM14.1667 4.99996H6.66666C5.75 4.99996 5 5.74996 5 6.66663V14.1666C5 14.625 5.375 15 5.83333 15C6.29166 15 6.66666 14.625 6.66666 14.1666V6.66663H14.1667C14.625 6.66663 15 6.29163 15 5.83329C15 5.37496 14.625 4.99996 14.1667 4.99996ZM16.6667 8.33329H10C9.08333 8.33329 8.33333 9.08329 8.33333 9.99996V16.6666C8.33333 17.5833 9.08333 18.3333 10 18.3333H16.6667C17.5833 18.3333 18.3333 17.5833 18.3333 16.6666V9.99996C18.3333 9.08329 17.5833 8.33329 16.6667 8.33329Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Slides
