import { IconSVGProps } from '@/components/ui/icon'

function ModalDeleteIcon({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 84 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M40.6174 0.955078L23.7889 101.679H1.28516V0.955078H40.6174Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.735352 0.955302C0.735352 0.56901 1.0485 0.255859 1.43479 0.255859H62.9746C63.3609 0.255859 63.6741 0.56901 63.6741 0.955302V101.679C63.6741 102.066 63.3609 102.379 62.9746 102.379H1.43479C1.0485 102.379 0.735352 102.066 0.735352 101.679V0.955302ZM2.13424 1.65474V100.98H62.2752V1.65474H2.13424Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5137 20.5471C17.5137 20.1608 17.8268 19.8477 18.2131 19.8477H82.5659C82.9522 19.8477 83.2654 20.1608 83.2654 20.5471V101.679C83.2654 102.065 82.9522 102.378 82.5659 102.378H18.2131C17.8268 102.378 17.5137 102.065 17.5137 101.679V20.5471ZM18.9126 21.2465V100.98H81.8665V21.2465H18.9126Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.5842 43.6371H26.6025V36.6426H54.5842V43.6371Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.5842 63.2289H26.6025V56.2344H54.5842V63.2289Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.5842 82.8207H26.6025V75.8262H54.5842V82.8207Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default ModalDeleteIcon
