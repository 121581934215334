import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { Footer } from '@/components/structure/footer'
import { Navbar } from '@/components/structure/navbar'
import { Sidebar } from '@/components/structure/sidebar'
import { APP_ROUTES } from '@/constants/app-routes'
import { cn } from '@/lib/utils'
import { useStore } from '@/store'

export function AppLayout() {
  const navigate = useNavigate()

  const { isOpen, isAuthenticated } = useStore((state) => {
    return {
      isOpen: state.isOpen,
      isAuthenticated: state.isAuthenticated
    }
  })

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(APP_ROUTES.SIGN_IN)
    }
  }, [isAuthenticated, navigate])

  return (
    <>
      <div className="lg:hidden">
        <Navbar />
      </div>
      <Sidebar />
      <main
        className={cn(
          'min-h-[calc(100vh_-_60px)] flex-1 overflow-auto bg-background p-6 transition-[margin-left] duration-300 ease-in-out dark:bg-zinc-900',
          !isOpen ? 'lg:ml-[90px]' : 'lg:ml-72'
        )}
      >
        <Outlet />
      </main>
      <Footer />
    </>
  )
}
