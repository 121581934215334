import { Menu } from '@/components/structure/sidebar/menu'
import { SidebarToggle } from '@/components/structure/sidebar/sidebar-toggle'
import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'
import { cn } from '@/lib/utils'
import { useStore } from '@/store'

export function Sidebar() {
  const { isOpen, setOpen } = useStore((state) => {
    return {
      isOpen: state.isOpen,
      setOpen: state.setOpen
    }
  })

  return (
    <aside
      className={cn(
        'fixed left-0 top-0 z-20 h-screen -translate-x-full transition-[width] duration-300 ease-in-out lg:translate-x-0',
        !isOpen ? 'w-[90px]' : 'w-72'
      )}
    >
      <SidebarToggle isOpen={isOpen} setIsOpen={() => setOpen(!isOpen)} />
      <div className="relative flex h-full flex-col overflow-y-auto border-r px-3 py-4 dark:shadow-zinc-800">
        <Button
          className={cn(
            'mb-1 transition-transform duration-300 ease-in-out',
            !isOpen ? 'translate-x-1' : 'translate-x-0'
          )}
          variant="link"
          asChild
        >
          <a href="/" className="float-left mr-auto flex items-center gap-2 pl-5">
            {isOpen ? (
              <Icon name="large-logo" width={95} height={24} />
            ) : (
              <Icon name="small-logo" width={30} height={30} />
            )}
          </a>
        </Button>
        <Menu isOpen={isOpen} />
      </div>
    </aside>
  )
}
