import { IconSVGProps } from '@/components/ui/icon'

function Home({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66514 12.885V9.55167H9.33181V12.885C9.33181 13.2517 9.63181 13.5517 9.99848 13.5517H11.9985C12.3651 13.5517 12.6651 13.2517 12.6651 12.885V8.21833H13.7985C14.1051 8.21833 14.2518 7.83833 14.0185 7.63833L8.44515 2.61833C8.19181 2.39167 7.80514 2.39167 7.55181 2.61833L1.97848 7.63833C1.75181 7.83833 1.89181 8.21833 2.19848 8.21833H3.33181V12.885C3.33181 13.2517 3.63181 13.5517 3.99848 13.5517H5.99848C6.36515 13.5517 6.66514 13.2517 6.66514 12.885Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Home
