import { IconSVGProps } from '@/components/ui/icon'

function Assignment({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2.66666H9.88C9.6 1.89333 8.86667 1.33333 8 1.33333C7.13333 1.33333 6.4 1.89333 6.12 2.66666H3.33333C2.6 2.66666 2 3.26666 2 3.99999V13.3333C2 14.0667 2.6 14.6667 3.33333 14.6667H12.6667C13.4 14.6667 14 14.0667 14 13.3333V3.99999C14 3.26666 13.4 2.66666 12.6667 2.66666ZM8 2.66666C8.36667 2.66666 8.66667 2.96666 8.66667 3.33333C8.66667 3.7 8.36667 3.99999 8 3.99999C7.63333 3.99999 7.33333 3.7 7.33333 3.33333C7.33333 2.96666 7.63333 2.66666 8 2.66666ZM8.66667 12H5.33333C4.96667 12 4.66667 11.7 4.66667 11.3333C4.66667 10.9667 4.96667 10.6667 5.33333 10.6667H8.66667C9.03333 10.6667 9.33333 10.9667 9.33333 11.3333C9.33333 11.7 9.03333 12 8.66667 12ZM10.6667 9.33333H5.33333C4.96667 9.33333 4.66667 9.03333 4.66667 8.66666C4.66667 8.29999 4.96667 8 5.33333 8H10.6667C11.0333 8 11.3333 8.29999 11.3333 8.66666C11.3333 9.03333 11.0333 9.33333 10.6667 9.33333ZM10.6667 6.66666H5.33333C4.96667 6.66666 4.66667 6.36666 4.66667 6C4.66667 5.63333 4.96667 5.33333 5.33333 5.33333H10.6667C11.0333 5.33333 11.3333 5.63333 11.3333 6C11.3333 6.36666 11.0333 6.66666 10.6667 6.66666Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Assignment
