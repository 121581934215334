import { IconSVGProps } from '@/components/ui/icon'

function Block({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 1.66675C5.39935 1.66675 1.66602 5.40008 1.66602 10.0001C1.66602 14.6001 5.39935 18.3334 9.99935 18.3334C14.5993 18.3334 18.3327 14.6001 18.3327 10.0001C18.3327 5.40008 14.5993 1.66675 9.99935 1.66675ZM3.33268 10.0001C3.33268 6.31675 6.31602 3.33341 9.99935 3.33341C11.541 3.33341 12.9577 3.85841 14.0827 4.74175L4.74102 14.0834C3.85768 12.9584 3.33268 11.5417 3.33268 10.0001ZM9.99935 16.6667C8.45768 16.6667 7.04102 16.1417 5.91602 15.2584L15.2577 5.91675C16.141 7.04175 16.666 8.45842 16.666 10.0001C16.666 13.6834 13.6827 16.6667 9.99935 16.6667Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Block
