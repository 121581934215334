import { IconSVGProps } from '@/components/ui/icon'

function World({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 1.6665C5.39935 1.6665 1.66602 5.39984 1.66602 9.99984C1.66602 14.5998 5.39935 18.3332 9.99935 18.3332C14.5993 18.3332 18.3327 14.5998 18.3327 9.99984C18.3327 5.39984 14.5993 1.6665 9.99935 1.6665ZM9.16602 16.6082C5.87435 16.1998 3.33268 13.3998 3.33268 9.99984C3.33268 9.48317 3.39935 8.9915 3.50768 8.50817L7.49935 12.4998V13.3332C7.49935 14.2498 8.24935 14.9998 9.16602 14.9998V16.6082ZM14.916 14.4915C14.6993 13.8165 14.0827 13.3332 13.3327 13.3332H12.4993V10.8332C12.4993 10.3748 12.1243 9.99984 11.666 9.99984H6.66602V8.33317H8.33268C8.79102 8.33317 9.16602 7.95817 9.16602 7.49984V5.83317H10.8327C11.7493 5.83317 12.4993 5.08317 12.4993 4.1665V3.82484C14.941 4.8165 16.666 7.20817 16.666 9.99984C16.666 11.7332 15.9993 13.3082 14.916 14.4915Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default World
