import { IconSVGProps } from '@/components/ui/icon'

function Logout({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.40291 3.33333H7.40291C7.76958 3.33333 8.06958 3.03333 8.06958 2.66667C8.06958 2.3 7.76958 2 7.40291 2H3.40291C2.66958 2 2.06958 2.6 2.06958 3.33333V12.6667C2.06958 13.4 2.66958 14 3.40291 14H7.40291C7.76958 14 8.06958 13.7 8.06958 13.3333C8.06958 12.9667 7.76958 12.6667 7.40291 12.6667H3.40291V3.33333Z"
        fill="currentcolor"
      />
      <path
        d="M13.8362 7.76667L11.9762 5.90667C11.7629 5.69333 11.4029 5.84 11.4029 6.14V7.33333H6.73625C6.36958 7.33333 6.06958 7.63333 6.06958 8C6.06958 8.36667 6.36958 8.66667 6.73625 8.66667H11.4029V9.86C11.4029 10.16 11.7629 10.3067 11.9696 10.0933L13.8296 8.23333C13.9629 8.10667 13.9629 7.89333 13.8362 7.76667Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Logout
