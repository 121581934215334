import { IconSVGProps } from '@/components/ui/icon'

function Brain({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 97 96"
      fill="none"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.2096 48.5606C60.2096 42.016 54.9261 36.6928 48.3418 36.6928V32.4961C57.2535 32.4961 64.4063 39.7078 64.4063 48.5606C64.4063 57.4181 57.1993 64.6251 48.3418 64.6251V60.4284C54.8816 60.4284 60.2096 55.1003 60.2096 48.5606Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3006 21.5014C13.8424 19.2333 5.19473 24.2892 2.97379 32.7004L2.9731 32.703C0.704974 41.1612 5.7609 49.8089 14.1721 52.0299C14.5456 52.1285 14.7684 52.5112 14.6698 52.8847C14.5712 53.2582 14.1885 53.481 13.815 53.3824C4.65792 50.9645 -0.847032 41.5508 1.6216 32.342C4.04014 23.1853 13.4542 17.6809 22.663 20.1503C23.0361 20.2503 23.2574 20.6339 23.1574 21.007C23.0573 21.3801 22.6738 21.6015 22.3006 21.5014Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2961 63.7595C25.5693 64.0327 25.5693 64.4756 25.2961 64.7487C22.3615 67.6833 22.3615 72.4716 25.2961 75.4062C28.2308 78.3408 33.019 78.3408 35.9537 75.4062C36.2268 75.1331 36.6697 75.1331 36.9428 75.4062C37.216 75.6794 37.216 76.1222 36.9428 76.3954C33.4619 79.8763 27.7879 79.8763 24.307 76.3954C20.8261 72.9145 20.8261 67.2405 24.307 63.7595C24.5801 63.4864 25.023 63.4864 25.2961 63.7595Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.8941 22.1594C29.9595 19.2248 25.1712 19.2248 22.2366 22.1594C19.3019 25.094 19.3019 29.8824 22.2366 32.817C22.5097 33.0901 22.5097 33.533 22.2366 33.8061C21.9634 34.0793 21.5206 34.0793 21.2474 33.8061C17.7665 30.3252 17.7665 24.6512 21.2474 21.1703C24.7283 17.6893 30.4023 17.6893 33.8832 21.1703C34.1564 21.4434 34.1564 21.8863 33.8832 22.1594C33.6101 22.4326 33.1672 22.4326 32.8941 22.1594Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6458 75.992C18.0311 76.019 18.3216 76.3533 18.2945 76.7387C17.9162 82.1304 20.2805 87.5634 25.0523 90.8706L25.0532 90.8712C32.1891 95.8333 42.0659 94.0834 47.0263 86.9025C47.2459 86.5847 47.6815 86.505 47.9994 86.7245C48.3172 86.9441 48.3969 87.3797 48.1773 87.6976C42.7742 95.5192 32.0225 97.4212 24.2546 92.0197C19.0583 88.4178 16.4879 82.5005 16.8991 76.6407C16.9261 76.2554 17.2604 75.9649 17.6458 75.992Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.88839 47.9178C7.16154 48.1909 7.16154 48.6338 6.88839 48.9069C0.696651 55.0987 0.696651 65.0687 6.88839 71.2604C11.5208 75.8928 18.278 77.0742 23.9961 74.7586C24.3541 74.6136 24.7619 74.7864 24.9069 75.1444C25.0519 75.5025 24.8792 75.9102 24.5212 76.0552C18.2964 78.576 10.9395 77.2898 5.89923 72.2496C-0.838807 65.5115 -0.838807 54.6558 5.89923 47.9178C6.17238 47.6446 6.61525 47.6446 6.88839 47.9178Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1061 4.67796C27.384 -0.141324 37.1645 1.84371 41.9845 9.12097C42.1978 9.44303 42.6318 9.53118 42.9539 9.31787C43.2759 9.10456 43.3641 8.67056 43.1508 8.34851C37.9036 0.426356 27.254 -1.73428 19.3322 3.51266L19.3322 3.51266L19.3293 3.51461C13.4172 7.47312 10.6895 14.4169 11.9245 21.0038C11.9957 21.3835 12.3612 21.6336 12.7409 21.5624C13.1205 21.4912 13.3706 21.1257 13.2994 20.746C12.1658 14.6997 14.6686 8.31956 20.1061 4.67796Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.3674 44.1702C85.5029 44.5319 85.3195 44.935 84.9577 45.0705L73.7552 49.2653C73.6767 49.2946 73.5937 49.3097 73.5099 49.3097H65.1203C64.734 49.3097 64.4209 48.9965 64.4209 48.6102C64.4209 48.2239 64.734 47.9108 65.1203 47.9108H73.3832L84.4671 43.7604C84.8289 43.625 85.232 43.8084 85.3674 44.1702Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.0669 64.7461C48.422 64.594 48.8331 64.7586 48.9852 65.1137L57.1931 84.2817H62.3083C62.6946 84.2817 63.0078 84.5948 63.0078 84.9811C63.0078 85.3674 62.6946 85.6806 62.3083 85.6806H56.7318C56.4519 85.6806 56.199 85.5137 56.0888 85.2565L47.6992 65.6644C47.5472 65.3093 47.7118 64.8982 48.0669 64.7461Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.5872 22.5515C71.8603 22.8247 71.8603 23.2676 71.5872 23.5407L59.3483 35.7796C59.0752 36.0528 58.6323 36.0528 58.3592 35.7796C58.086 35.5065 58.086 35.0636 58.3592 34.7904L70.598 22.5515C70.8712 22.2784 71.314 22.2784 71.5872 22.5515Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.342 14.3027C48.7283 14.3027 49.0415 14.6159 49.0415 15.0022V31.8307C49.0415 32.217 48.7283 32.5301 48.342 32.5301C47.9557 32.5301 47.6426 32.217 47.6426 31.8307V15.0022C47.6426 14.6159 47.9557 14.3027 48.342 14.3027Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.2897 38.1069C87.5961 38.1069 85.4126 40.2905 85.4126 42.9841C85.4126 45.6776 87.5961 47.8612 90.2897 47.8612C92.9833 47.8612 95.1668 45.6776 95.1668 42.9841C95.1668 40.2905 92.9833 38.1069 90.2897 38.1069ZM84.0137 42.9841C84.0137 39.5179 86.8235 36.708 90.2897 36.708C93.7559 36.708 96.5657 39.5179 96.5657 42.9841C96.5657 46.4502 93.7559 49.2601 90.2897 49.2601C86.8236 49.2601 84.0137 46.4502 84.0137 42.9841Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.5104 12.9379C70.8168 12.9379 68.6333 15.1215 68.6333 17.8151C68.6333 20.5087 70.8168 22.6922 73.5104 22.6922C76.204 22.6922 78.3876 20.5087 78.3876 17.8151C78.3876 15.1215 76.204 12.9379 73.5104 12.9379ZM67.2344 17.8151C67.2344 14.349 70.0442 11.5391 73.5104 11.5391C76.9766 11.5391 79.7864 14.349 79.7864 17.8151C79.7864 21.2813 76.9766 24.0911 73.5104 24.0911C70.0443 24.0911 67.2344 21.2813 67.2344 17.8151Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3415 4.5483C45.6479 4.5483 43.4643 6.73189 43.4643 9.42547C43.4643 12.119 45.6479 14.3026 48.3415 14.3026C51.035 14.3026 53.2186 12.119 53.2186 9.42547C53.2186 6.73189 51.035 4.5483 48.3415 4.5483ZM42.0654 9.42547C42.0654 5.95932 44.8753 3.14941 48.3415 3.14941C51.8076 3.14941 54.6175 5.95932 54.6175 9.42547C54.6175 12.8916 51.8076 15.7015 48.3415 15.7015C44.8753 15.7015 42.0654 12.8916 42.0654 9.42547Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.9001 63.3247C79.2065 63.3247 77.0229 65.5083 77.0229 68.2018C77.0229 70.8954 79.2065 73.079 81.9001 73.079C84.5936 73.079 86.7772 70.8954 86.7772 68.2018C86.7772 65.5083 84.5936 63.3247 81.9001 63.3247ZM75.624 68.2018C75.624 64.7357 78.4339 61.9258 81.9001 61.9258C85.3662 61.9258 88.1761 64.7357 88.1761 68.2018C88.1761 71.668 85.3662 74.4779 81.9001 74.4779C78.4339 74.4779 75.624 71.668 75.624 68.2018Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.9342 80.104C65.2407 80.104 63.0571 82.2876 63.0571 84.9811C63.0571 87.6747 65.2407 89.8583 67.9342 89.8583C70.6278 89.8583 72.8114 87.6747 72.8114 84.9811C72.8114 82.2876 70.6278 80.104 67.9342 80.104ZM61.6582 84.9811C61.6582 81.515 64.4681 78.7051 67.9342 78.7051C71.4004 78.7051 74.2103 81.515 74.2103 84.9811C74.2103 88.4473 71.4004 91.2572 67.9342 91.2572C64.4681 91.2572 61.6582 88.4473 61.6582 84.9811Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.4666 59.6301C60.7578 59.3763 61.1997 59.4068 61.4534 59.698L68.2521 67.5021H76.3233C76.7096 67.5021 77.0228 67.8153 77.0228 68.2016C77.0228 68.5879 76.7096 68.901 76.3233 68.901H67.9338C67.7315 68.901 67.5392 68.8135 67.4064 68.661L60.3986 60.6169C60.1449 60.3256 60.1753 59.8838 60.4666 59.6301Z"
        fill="#DC3545"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.3418 32.5297C39.4613 32.5297 32.2622 39.7289 32.2622 48.6094C32.2622 57.49 39.4613 64.6891 48.3418 64.6891C57.2224 64.6891 64.4215 57.49 64.4215 48.6094C64.4215 39.7289 57.2224 32.5297 48.3418 32.5297ZM30.8633 48.6094C30.8633 38.9563 38.6887 31.1309 48.3418 31.1309C57.995 31.1309 65.8204 38.9563 65.8204 48.6094C65.8204 58.2626 57.995 66.088 48.3418 66.088C38.6887 66.088 30.8633 58.2626 30.8633 48.6094Z"
        fill="#DC3545"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.4211 48.5601C50.4211 47.4117 49.4993 46.4808 48.3418 46.4808V39.4863C53.4025 39.4863 57.4156 43.5891 57.4156 48.5601C57.4156 53.5513 53.333 57.634 48.3418 57.634V50.6395C49.47 50.6395 50.4211 49.6884 50.4211 48.5601Z"
        fill="#DC3545"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6514 48.6094C26.6514 37.7406 35.6752 26.9189 48.3418 26.9189V31.1156C38.2086 31.1156 30.8481 39.8368 30.8481 48.6094C30.8481 53.0748 32.3312 57.4563 35.2098 60.694C38.0539 63.893 42.3699 66.1031 48.3418 66.1031V70.2998C41.2358 70.2998 35.7558 67.6243 32.0734 63.4824C28.4253 59.3792 26.6514 53.9647 26.6514 48.6094Z"
        fill="#DC3545"
      />
    </svg>
  )
}

export default Brain
