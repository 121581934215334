import { IconSVGProps } from '@/components/ui/icon'

function Location({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49984 1.44092C5.69984 1.44092 3.1665 3.58758 3.1665 6.90758C3.1665 9.02758 4.79984 11.5209 8.05984 14.3943C8.31317 14.6143 8.69317 14.6143 8.9465 14.3943C12.1998 11.5209 13.8332 9.02758 13.8332 6.90758C13.8332 3.58758 11.2998 1.44092 8.49984 1.44092ZM8.49984 8.10758C7.7665 8.10758 7.1665 7.50759 7.1665 6.77425C7.1665 6.04092 7.7665 5.44092 8.49984 5.44092C9.23317 5.44092 9.83317 6.04092 9.83317 6.77425C9.83317 7.50759 9.23317 8.10758 8.49984 8.10758Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Location
