import { IconSVGProps } from '@/components/ui/icon'

function FormatTextOptions({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.52 8.175L1.72 10.425C1.65333 10.6083 1.545 10.75 1.395 10.85C1.245 10.95 1.07833 11 0.895 11C0.578333 11 0.3325 10.8708 0.1575 10.6125C-0.0175 10.3542 -0.0466667 10.0833 0.07 9.8L3.52 0.6C3.58667 0.416667 3.70333 0.270833 3.87 0.1625C4.03667 0.0541667 4.21167 0 4.395 0H5.07C5.25333 0 5.42833 0.0541667 5.595 0.1625C5.76167 0.270833 5.87833 0.416667 5.945 0.6L9.395 9.825C9.51167 10.1083 9.4825 10.375 9.3075 10.625C9.1325 10.875 8.895 11 8.595 11C8.41167 11 8.24083 10.9458 8.0825 10.8375C7.92417 10.7292 7.81167 10.5833 7.745 10.4L6.97 8.175H2.52ZM3.095 6.575H6.37L4.745 2.025H4.645L3.095 6.575Z"
        fill="currentcolor"
      />
      <path
        d="M13 10C13 10.5523 12.5523 11 12 11C11.4477 11 11 10.5523 11 10C11 9.44771 11.4477 9 12 9C12.5523 9 13 9.44771 13 10Z"
        fill="currentcolor"
      />
      <path
        d="M13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z"
        fill="currentcolor"
      />
      <path
        d="M13 4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default FormatTextOptions
