import { IconSVGProps } from '@/components/ui/icon'

function Paid({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 1.33334C4.32004 1.33334 1.33337 4.32001 1.33337 8.00001C1.33337 11.68 4.32004 14.6667 8.00004 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00001C14.6667 4.32001 11.68 1.33334 8.00004 1.33334ZM8.94004 12.06V12.4467C8.94004 12.9333 8.54004 13.3333 8.05337 13.3333H8.04671C7.56004 13.3333 7.16004 12.9333 7.16004 12.4467V12.0467C6.27337 11.86 5.48671 11.3733 5.15337 10.5533C5.00004 10.1867 5.28671 9.78001 5.68671 9.78001H5.84671C6.09337 9.78001 6.29337 9.94668 6.38671 10.18C6.58004 10.68 7.08671 11.0267 8.06004 11.0267C9.36671 11.0267 9.66004 10.3733 9.66004 9.96668C9.66004 9.41334 9.36671 8.89334 7.88004 8.54001C6.22671 8.14001 5.09337 7.46001 5.09337 6.09334C5.09337 4.94668 6.02004 4.20001 7.16671 3.95334V3.55334C7.16671 3.06668 7.56671 2.66668 8.05337 2.66668H8.06004C8.54671 2.66668 8.94671 3.06668 8.94671 3.55334V3.96668C9.86671 4.19334 10.4467 4.76668 10.7 5.47334C10.8334 5.84001 10.5534 6.22668 10.16 6.22668H9.98671C9.74004 6.22668 9.54004 6.05334 9.47337 5.81334C9.32004 5.30668 8.90004 4.98001 8.06004 4.98001C7.06004 4.98001 6.46004 5.43334 6.46004 6.07334C6.46004 6.63334 6.89337 7.00001 8.24004 7.34668C9.58671 7.69334 11.0267 8.27334 11.0267 9.95334C11.0134 11.1733 10.1 11.84 8.94004 12.06Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Paid
