import { IconSVGProps } from '@/components/ui/icon'

function ChevronLeft({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill="none"
    >
      <path
        fill="currentcolor"
        d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"
      />
    </svg>
  )
}

export default ChevronLeft
