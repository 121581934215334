import { IconSVGProps } from '@/components/ui/icon'

function Filter({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.18751 4.2075C4.92751 6.4425 7.50001 9.75 7.50001 9.75V13.5C7.50001 14.325 8.17501 15 9.00001 15C9.82501 15 10.5 14.325 10.5 13.5V9.75C10.5 9.75 13.0725 6.4425 14.8125 4.2075C15.195 3.7125 14.8425 3 14.2125 3H3.78001C3.15751 3 2.80501 3.7125 3.18751 4.2075Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Filter
