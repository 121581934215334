import { IconSVGProps } from '@/components/ui/icon'

function Language({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99449 1.1665C3.77449 1.1665 1.16699 3.77984 1.16699 6.99984C1.16699 10.2198 3.77449 12.8332 6.99449 12.8332C10.2203 12.8332 12.8337 10.2198 12.8337 6.99984C12.8337 3.77984 10.2203 1.1665 6.99449 1.1665ZM11.037 4.6665H9.31616C9.12949 3.93734 8.86116 3.23734 8.51116 2.58984C9.58449 2.95734 10.477 3.704 11.037 4.6665ZM7.00033 2.3565C7.48449 3.0565 7.86366 3.83234 8.11449 4.6665H5.88616C6.13699 3.83234 6.51616 3.0565 7.00033 2.3565ZM2.48533 8.1665C2.39199 7.79317 2.33366 7.40234 2.33366 6.99984C2.33366 6.59734 2.39199 6.2065 2.48533 5.83317H4.45699C4.41033 6.21817 4.37533 6.60317 4.37533 6.99984C4.37533 7.3965 4.41033 7.7815 4.45699 8.1665H2.48533ZM2.96366 9.33317H4.68449C4.87116 10.0623 5.13949 10.7623 5.48949 11.4098C4.41616 11.0423 3.52366 10.3015 2.96366 9.33317ZM4.68449 4.6665H2.96366C3.52366 3.69817 4.41616 2.95734 5.48949 2.58984C5.13949 3.23734 4.87116 3.93734 4.68449 4.6665ZM7.00033 11.6432C6.51616 10.9432 6.13699 10.1673 5.88616 9.33317H8.11449C7.86366 10.1673 7.48449 10.9432 7.00033 11.6432ZM8.36533 8.1665H5.63533C5.58283 7.7815 5.54199 7.3965 5.54199 6.99984C5.54199 6.60317 5.58283 6.21234 5.63533 5.83317H8.36533C8.41783 6.21234 8.45866 6.60317 8.45866 6.99984C8.45866 7.3965 8.41783 7.7815 8.36533 8.1665ZM8.51116 11.4098C8.86116 10.7623 9.12949 10.0623 9.31616 9.33317H11.037C10.477 10.2957 9.58449 11.0423 8.51116 11.4098ZM9.54366 8.1665C9.59033 7.7815 9.62533 7.3965 9.62533 6.99984C9.62533 6.60317 9.59033 6.21817 9.54366 5.83317H11.5153C11.6087 6.2065 11.667 6.59734 11.667 6.99984C11.667 7.40234 11.6087 7.79317 11.5153 8.1665H9.54366Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Language
