import { IconSVGProps } from '@/components/ui/icon'

function Unsatisfied({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 120 119"
      fill="none"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.6109 16.2668C36.0491 16.2668 16.9485 35.3674 16.9485 58.9291C16.9485 82.4909 36.0491 101.592 59.6109 101.592C83.1727 101.592 102.273 82.4909 102.273 58.9291C102.273 35.3674 83.1727 16.2668 59.6109 16.2668ZM12.7518 58.9291C12.7518 33.0496 33.7314 12.0701 59.6109 12.0701C85.4905 12.0701 106.47 33.0496 106.47 58.9291C106.47 84.8087 85.4905 105.788 59.6109 105.788C33.7314 105.788 12.7518 84.8087 12.7518 58.9291Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.6111 3.66524C29.0897 3.66524 4.34725 28.4077 4.34725 58.929C4.34725 89.4504 29.0897 114.193 59.6111 114.193C90.1324 114.193 114.875 89.4504 114.875 58.929C114.875 28.4077 90.1324 3.66524 59.6111 3.66524ZM2.94836 58.929C2.94836 27.6351 28.3171 2.26636 59.6111 2.26636C90.905 2.26636 116.274 27.6351 116.274 58.929C116.274 90.223 90.905 115.592 59.6111 115.592C28.3171 115.592 2.94836 90.223 2.94836 58.929Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.3902 84.7974C76.0039 84.7974 75.6907 84.4842 75.6907 84.0979C75.6907 75.2064 68.5026 68.0183 59.611 68.0183C50.7195 68.0183 43.5314 75.2064 43.5314 84.0979C43.5314 84.4842 43.2182 84.7974 42.8319 84.7974C42.4456 84.7974 42.1325 84.4842 42.1325 84.0979C42.1325 74.4338 49.9469 66.6194 59.611 66.6194C69.2752 66.6194 77.0896 74.4338 77.0896 84.0979C77.0896 84.4842 76.7764 84.7974 76.3902 84.7974Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.3407 48.3767C73.6713 48.3767 71.4636 50.5764 71.4636 53.3032C71.4636 53.6895 71.1504 54.0026 70.7641 54.0026C70.3778 54.0026 70.0647 53.6895 70.0647 53.3032C70.0647 49.8118 72.8907 46.9778 76.3407 46.9778C79.8441 46.9778 82.6168 49.8159 82.6168 53.3032C82.6168 53.6895 82.3036 54.0026 81.9173 54.0026C81.531 54.0026 81.2179 53.6895 81.2179 53.3032C81.2179 50.5723 79.0555 48.3767 76.3407 48.3767Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.7822 48.3767C40.1128 48.3767 37.9051 50.5764 37.9051 53.3032C37.9051 53.6895 37.592 54.0026 37.2057 54.0026C36.8194 54.0026 36.5062 53.6895 36.5062 53.3032C36.5062 49.8118 39.3322 46.9778 42.7822 46.9778C46.2856 46.9778 49.0583 49.8159 49.0583 53.3032C49.0583 53.6895 48.7451 54.0026 48.3588 54.0026C47.9726 54.0026 47.6594 53.6895 47.6594 53.3032C47.6594 50.5723 45.497 48.3767 42.7822 48.3767Z"
        fill="#E85D0C"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.401 29.7684C14.2967 45.8726 14.2967 72.0347 30.401 88.139C46.5052 104.243 72.6673 104.243 88.7716 88.139C104.876 72.0347 104.876 45.8726 88.7716 29.7684L93.7174 24.8225C112.553 43.6583 112.553 74.2491 93.7174 93.0849C74.8816 111.921 44.2909 111.921 25.4551 93.0849C6.6193 74.2491 6.6193 43.6583 25.4551 24.8225L30.401 29.7684Z"
        fill="#E85D0C"
      />
    </svg>
  )
}

export default Unsatisfied
