import { Outlet } from 'react-router-dom'

import { Footer } from '@/components/structure/footer'
import { Header } from '@/components/structure/header'

export function AuthLayout() {
  return (
    <main>
      <Header hiddenRegisterButtons={true} />
      <div className="flex min-h-[calc(100vh_-_116px)] items-center justify-center bg-background dark:bg-zinc-900">
        <Outlet />
      </div>
      <Footer isLoginPage={true} />
    </main>
  )
}
