import { IconSVGProps } from '@/components/ui/icon'

function LockReset({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0523 2.25116C6.23482 2.14616 3.10732 5.20616 3.10732 9.00116H1.76482C1.42732 9.00116 1.26232 9.40616 1.50232 9.63866L3.59482 11.7312C3.74482 11.8812 3.97732 11.8812 4.12732 11.7312L6.22732 9.63866C6.45232 9.40616 6.28732 9.00116 5.94982 9.00116H4.60732C4.60732 6.08366 7.00732 3.70616 9.93232 3.75116C12.7148 3.78866 15.0623 6.13616 15.1073 8.92616C15.1523 11.8587 12.7823 14.2512 9.85732 14.2512C8.66482 14.2512 7.56982 13.8537 6.68482 13.1787C6.38482 12.9537 5.96482 12.9762 5.70232 13.2462C5.37982 13.5687 5.40982 14.1012 5.76982 14.3712C6.90232 15.2337 8.31982 15.7512 9.85732 15.7512C13.6523 15.7512 16.7123 12.6237 16.6073 8.81366C16.5098 5.28866 13.5698 2.34866 10.0523 2.25116ZM11.3573 8.25116V7.50116C11.3573 6.67616 10.6823 6.00116 9.85732 6.00116C9.03232 6.00116 8.35732 6.67616 8.35732 7.50116V8.25116C7.94482 8.25116 7.60732 8.58866 7.60732 9.00116V11.2512C7.60732 11.6637 7.94482 12.0012 8.35732 12.0012H11.3573C11.7698 12.0012 12.1073 11.6637 12.1073 11.2512V9.00116C12.1073 8.58866 11.7698 8.25116 11.3573 8.25116ZM10.6073 8.25116H9.10732V7.50116C9.10732 7.08866 9.44482 6.75116 9.85732 6.75116C10.2698 6.75116 10.6073 7.08866 10.6073 7.50116V8.25116Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default LockReset
