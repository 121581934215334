import { IconSVGProps } from '@/components/ui/icon'

function MenuOpen({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.50313 15H12.6698C13.1281 15 13.5031 14.625 13.5031 14.1667C13.5031 13.7083 13.1281 13.3333 12.6698 13.3333H3.50313C3.0448 13.3333 2.6698 13.7083 2.6698 14.1667C2.6698 14.625 3.0448 15 3.50313 15ZM3.50313 10.8333H10.1698C10.6281 10.8333 11.0031 10.4583 11.0031 10C11.0031 9.54167 10.6281 9.16667 10.1698 9.16667H3.50313C3.0448 9.16667 2.6698 9.54167 2.6698 10C2.6698 10.4583 3.0448 10.8333 3.50313 10.8333ZM2.6698 5.83333C2.6698 6.29167 3.0448 6.66667 3.50313 6.66667H12.6698C13.1281 6.66667 13.5031 6.29167 13.5031 5.83333C13.5031 5.375 13.1281 5 12.6698 5H3.50313C3.0448 5 2.6698 5.375 2.6698 5.83333ZM17.0865 12.4L14.6865 10L17.0865 7.6C17.4115 7.275 17.4115 6.75 17.0865 6.425C16.7615 6.1 16.2365 6.1 15.9115 6.425L12.9198 9.41667C12.5948 9.74167 12.5948 10.2667 12.9198 10.5917L15.9115 13.5833C16.2365 13.9083 16.7615 13.9083 17.0865 13.5833C17.4031 13.2583 17.4115 12.725 17.0865 12.4Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default MenuOpen
