import { IconSVGProps } from '@/components/ui/icon'

function FormatHOptions({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10C0.716667 10 0.479167 9.90417 0.2875 9.7125C0.0958333 9.52083 0 9.28333 0 9V1C0 0.716667 0.0958333 0.479167 0.2875 0.2875C0.479167 0.0958333 0.716667 0 1 0C1.28333 0 1.52083 0.0958333 1.7125 0.2875C1.90417 0.479167 2 0.716667 2 1V4H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V9C8 9.28333 7.90417 9.52083 7.7125 9.7125C7.52083 9.90417 7.28333 10 7 10C6.71667 10 6.47917 9.90417 6.2875 9.7125C6.09583 9.52083 6 9.28333 6 9V6H2V9C2 9.28333 1.90417 9.52083 1.7125 9.7125C1.52083 9.90417 1.28333 10 1 10Z"
        fill="currentcolor"
      />
      <path
        d="M12 9C12 9.55229 11.5523 10 11 10C10.4477 10 10 9.55229 10 9C10 8.44771 10.4477 8 11 8C11.5523 8 12 8.44771 12 9Z"
        fill="currentcolor"
      />
      <path
        d="M12 6C12 6.55228 11.5523 7 11 7C10.4477 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5C11.5523 5 12 5.44772 12 6Z"
        fill="currentcolor"
      />
      <path
        d="M12 3C12 3.55228 11.5523 4 11 4C10.4477 4 10 3.55228 10 3C10 2.44772 10.4477 2 11 2C11.5523 2 12 2.44772 12 3Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default FormatHOptions
