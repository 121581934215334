import { Outlet } from 'react-router-dom'

import { Footer } from '@/components/structure/footer'
import { Header } from '@/components/structure/header'
import { cn } from '@/lib/utils'

interface ExternalLayoutProps {
  hideRegisterButtons?: boolean
}

export function ExternalLayout({ hideRegisterButtons = false }: ExternalLayoutProps) {
  return (
    <>
      <Header hiddenRegisterButtons={hideRegisterButtons} />
      <main
        className={cn(
          'mx-auto min-h-[calc(100vh_-_116px)] max-w-[1072px] flex-1 overflow-auto bg-background px-8 py-8 dark:bg-zinc-900'
        )}
      >
        <Outlet />
      </main>

      <Footer />
    </>
  )
}
