import { IconSVGProps } from '@/components/ui/icon'

function LightBulb({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 14.6667C8.73333 14.6667 9.33333 14.0667 9.33333 13.3333H6.66667C6.66667 14.0667 7.26667 14.6667 8 14.6667Z"
        fill="currentcolor"
      />
      <path
        d="M6 12.6667H10C10.3667 12.6667 10.6667 12.3667 10.6667 12C10.6667 11.6333 10.3667 11.3333 10 11.3333H6C5.63333 11.3333 5.33333 11.6333 5.33333 12C5.33333 12.3667 5.63333 12.6667 6 12.6667Z"
        fill="currentcolor"
      />
      <path
        d="M8 1.33333C5.24 1.33333 3 3.57333 3 6.33333C3 8.87999 4.77333 10.24 5.51333 10.6667H10.4867C11.2267 10.24 13 8.87999 13 6.33333C13 3.57333 10.76 1.33333 8 1.33333Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default LightBulb
