import { IconSVGProps } from '@/components/ui/icon'

function AdditionalField({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 6.26904V10.769C16.5 11.594 15.825 12.269 15 12.269H14.25V10.769H15V6.26904H3V10.769H7.5V12.269H3C2.175 12.269 1.5 11.594 1.5 10.769V6.26904C1.5 5.44404 2.175 4.76904 3 4.76904H15C15.825 4.76904 16.5 5.44404 16.5 6.26904ZM10.53 13.0115C10.665 13.304 11.0775 13.304 11.2125 13.0115L11.685 11.9615L12.735 11.489C13.0275 11.354 13.0275 10.9415 12.735 10.8065L11.685 10.334L11.2125 9.28404C11.0775 8.99154 10.665 8.99154 10.53 9.28404L10.0575 10.334L9.0075 10.8065C8.715 10.9415 8.715 11.354 9.0075 11.489L10.0575 11.9615L10.53 13.0115ZM12.555 9.59154C12.63 9.75654 12.87 9.75654 12.945 9.59154L13.215 8.99154L13.815 8.72154C13.98 8.64654 13.98 8.40654 13.815 8.33154L13.215 8.06154L12.945 7.46154C12.87 7.29654 12.63 7.29654 12.555 7.46154L12.285 8.06154L11.685 8.33154C11.52 8.40654 11.52 8.64654 11.685 8.72154L12.285 8.99154L12.555 9.59154Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AdditionalField
