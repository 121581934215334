import { IconSVGProps } from '@/components/ui/icon'

function VpnLock({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3283 6.70808C11.585 8.82558 10.4417 10.1206 10.15 10.4356C9.99833 9.96308 9.56667 9.62475 9.04167 9.62475H8.45833V7.87475C8.45833 7.55391 8.19583 7.29141 7.875 7.29141H4.375V6.12475H5.54167C5.8625 6.12475 6.125 5.86225 6.125 5.54141V4.37475H7.29167C7.93333 4.37475 8.45833 3.84975 8.45833 3.20808V1.72641C7.90417 1.55141 7.32083 1.45808 6.70833 1.45808C3.48833 1.45808 0.875 4.07141 0.875 7.29141C0.875 10.5114 3.48833 13.1247 6.70833 13.1247C10.0508 13.1247 12.8392 10.2897 12.5125 6.70808H11.3283ZM6.125 11.9172C3.82083 11.6314 2.04167 9.67141 2.04167 7.29141C2.04167 6.92975 2.08833 6.58558 2.16417 6.24725L4.95833 9.04141V9.62475C4.95833 10.2664 5.48333 10.7914 6.125 10.7914V11.9172ZM12.5417 2.62475V2.10558C12.5417 1.52225 12.145 0.985581 11.5733 0.892247C10.8383 0.769747 10.2083 1.33558 10.2083 2.04141V2.62475C9.8875 2.62475 9.625 2.88725 9.625 3.20808V4.95808C9.625 5.27891 9.8875 5.54141 10.2083 5.54141H12.5417C12.8625 5.54141 13.125 5.27891 13.125 4.95808V3.20808C13.125 2.88725 12.8625 2.62475 12.5417 2.62475ZM11.9583 2.62475H10.7917V2.04141C10.7917 1.72058 11.0542 1.45808 11.375 1.45808C11.6958 1.45808 11.9583 1.72058 11.9583 2.04141V2.62475Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default VpnLock
