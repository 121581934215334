import { SheetMenu } from '@/components/structure/navbar/sheet-menu'
import { UserNav } from '@/components/structure/navbar/user-nav'

export function Navbar() {
  return (
    <header className="sticky top-0 z-10 w-full border-b bg-default-off-gray backdrop-blur dark:shadow-secondary">
      <div className="mx-4 flex h-14 items-center sm:mx-8">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
        </div>
        <div className="flex flex-1 items-center justify-end space-x-2">
          <UserNav />
        </div>
      </div>
    </header>
  )
}
