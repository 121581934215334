import { IconSVGProps } from '@/components/ui/icon'

function Audio({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99938 4.16618V11.2912C9.21605 10.8412 8.24938 10.6662 7.22438 11.0245C6.10771 11.4245 5.24938 12.4162 5.04938 13.5828C4.66605 15.8662 6.59938 17.8162 8.87438 17.4578C10.5077 17.1995 11.666 15.6995 11.666 14.0412V5.83284H13.3327C14.2494 5.83284 14.9994 5.08284 14.9994 4.16618C14.9994 3.24951 14.2494 2.49951 13.3327 2.49951H11.666C10.7494 2.49951 9.99938 3.24951 9.99938 4.16618Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Audio
