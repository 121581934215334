import { IconSVGProps } from '@/components/ui/icon'

function Uppercase({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M700-240q-17 0-28.5-11.5T660-280v-208l-36 36q-11 11-28 11t-28-11q-11-11-11-28t11-28l104-104q12-12 28-12t28 12l104 104q11 11 11 28t-11 28q-11 11-28 11t-28-11l-36-36v208q0 17-11.5 28.5T700-240ZM236-353l-32 90q-4 11-13 17t-20 6q-19 0-29.5-15.5T138-288l138-368q4-11 14-17.5t21-6.5h27q11 0 21 6.5t14 17.5l138 369q7 17-3.5 32T479-240q-11 0-20.5-6.5T445-264l-31-89H236Zm23-64h131l-65-182h-4l-62 182Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Uppercase
