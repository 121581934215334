import { IconSVGProps } from '@/components/ui/icon'

function Translate({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.64082 11.7525C9.74582 11.4825 9.67832 11.175 9.46832 10.965L7.90082 9.42L7.92332 9.3975C9.22832 7.9425 10.1583 6.27 10.7058 4.5H12.1608C12.5658 4.5 12.9033 4.1625 12.9033 3.7575V3.7425C12.9033 3.3375 12.5658 3 12.1608 3H7.65332V2.25C7.65332 1.8375 7.31582 1.5 6.90332 1.5C6.49082 1.5 6.15332 1.8375 6.15332 2.25V3H1.64582C1.24082 3 0.90332 3.3375 0.90332 3.7425C0.90332 4.155 1.24082 4.485 1.64582 4.485H9.28082C8.77832 5.94 7.98332 7.3125 6.90332 8.5125C6.29582 7.845 5.78582 7.1175 5.35832 6.3525C5.23832 6.135 5.02082 6 4.77332 6C4.25582 6 3.92582 6.5625 4.18082 7.0125C4.65332 7.86 5.23082 8.67 5.90582 9.42L2.62832 12.6525C2.32832 12.945 2.32832 13.425 2.62832 13.7175C2.92082 14.01 3.39332 14.01 3.69332 13.7175L6.90332 10.5L8.41832 12.015C8.80082 12.3975 9.45332 12.255 9.64082 11.7525ZM13.2783 7.5C12.8283 7.5 12.4233 7.7775 12.2658 8.205L9.51332 15.555C9.33332 16.0125 9.67832 16.5 10.1658 16.5C10.4583 16.5 10.7208 16.32 10.8258 16.0425L11.4933 14.25H15.0558L15.7308 16.0425C15.8358 16.3125 16.0983 16.5 16.3908 16.5C16.8783 16.5 17.2233 16.0125 17.0508 15.555L14.2983 8.205C14.1333 7.7775 13.7283 7.5 13.2783 7.5ZM12.0633 12.75L13.2783 9.5025L14.4933 12.75H12.0633Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Translate
