import { IconSVGProps } from '@/components/ui/icon'

function Phone({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.62 8.3L6.87 5.6225C6.345 5.2475 5.6475 5.2475 5.13 5.6225L1.38 8.3C0.9825 8.585 0.75 9.035 0.75 9.5225V15.5C0.75 15.9125 1.0875 16.25 1.5 16.25H4.5V11.75H7.5V16.25H10.5C10.9125 16.25 11.25 15.9125 11.25 15.5V9.5225C11.25 9.035 11.0175 8.585 10.62 8.3Z"
        fill="currentcolor"
      />
      <path
        d="M15.7725 2.75H8.9775C8.16 2.75 7.5 3.41 7.5 4.2275L7.5675 4.295C7.6275 4.3325 7.6875 4.3625 7.7475 4.4L11.4975 7.0775C12.0675 7.4825 12.4725 8.0825 12.6525 8.75H14.25V10.25H12.75V11.75H14.25V13.25H12.75V16.25H15.7725C16.59 16.25 17.25 15.59 17.25 14.7725V4.2275C17.25 3.41 16.59 2.75 15.7725 2.75ZM14.25 7.25H12.75V5.75H14.25V7.25Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Phone
