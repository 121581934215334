import { IconSVGProps } from '@/components/ui/icon'

function Pencil({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99902 11.6408V13.6675C1.99902 13.8542 2.14569 14.0008 2.33236 14.0008H4.35902C4.44569 14.0008 4.53236 13.9675 4.59236 13.9008L11.8724 6.62751L9.37236 4.12751L2.09902 11.4008C2.03236 11.4675 1.99902 11.5475 1.99902 11.6408ZM13.8057 4.69418C14.0657 4.43418 14.0657 4.01418 13.8057 3.75418L12.2457 2.19418C11.9857 1.93418 11.5657 1.93418 11.3057 2.19418L10.0857 3.41418L12.5857 5.91418L13.8057 4.69418Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Pencil
