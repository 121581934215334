import { RouterProvider } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { Toaster } from 'sonner'

import enMessages from '@/locales/en/en.json'
import ptMessages from '@/locales/pt/pt.json'
import { TanstackProvider } from '@/providers/TanstackQuery'
import { router } from '@/routes'
import { useStore } from '@/store'

const messages = {
  en: enMessages,
  pt: ptMessages
}

export function App() {
  const { locale } = useStore((state) => {
    return {
      locale: state.locale
    }
  })

  if (!messages[locale] || !locale) {
    return null
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <TanstackProvider>
        <Toaster
          position="top-center"
          richColors
          toastOptions={{
            style: {
              padding: '1rem',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem'
            }
          }}
        />
        <RouterProvider router={router} />
      </TanstackProvider>
    </IntlProvider>
  )
}
