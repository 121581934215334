import { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { Icon } from '@/components/ui/icon'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { APP_ROUTES } from '@/constants/app-routes'
import { useStore } from '@/store'
import { getInitials } from '@/utils/get-initials.utils'

export function UserNav() {
  const navigate = useNavigate()

  const { logout, logged } = useStore((store) => {
    return { logout: store.logout, logged: store.userLogged }
  })

  function handleLogout() {
    logout()
    navigate(APP_ROUTES.SIGN_IN)
  }

  const userLogged = useMemo(() => {
    if (logged) {
      return {
        sub: logged.sub,
        email: logged.email,
        initials: getInitials(logged.sub as string)
      }
    }
    return null
  }, [logged])

  return (
    <DropdownMenu>
      <TooltipProvider disableHoverableContent>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="relative size-8 rounded-full">
                <Avatar className="size-8">
                  <AvatarImage src="#" alt="Avatar" />
                  <AvatarFallback className="bg-transparent">
                    {userLogged?.initials}
                  </AvatarFallback>
                </Avatar>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent side="bottom">{userLogged?.sub}</TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <DropdownMenuContent
        className="w-56 bg-background px-2 py-1.5"
        align="end"
        forceMount
      >
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{userLogged?.sub}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {userLogged?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem className="gap-3 hover:cursor-pointer" asChild>
            <Link to="/">
              <Icon name="home" />
              Início
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem className="gap-3 hover:cursor-pointer" asChild>
            <Link to="/common/profile">
              <Icon name="person" />
              Perfil
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="flex items-center gap-3 hover:cursor-pointer"
          onClick={handleLogout}
        >
          <Icon name="logout" className="text-danger-400" />
          <span className="text-danger-400">Sair</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
