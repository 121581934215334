import { IconSVGProps } from '@/components/ui/icon'

function Foundation({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 18 18"
      width={size}
      fill="none"
    >
      <path
        d="M3.74981 4.5H14.2498C14.6623 4.5 14.9998 4.1625 14.9998 3.75C14.9998 3.3375 14.6623 3 14.2498 3H3.74981C3.33731 3 2.99981 3.3375 2.99981 3.75C2.99981 4.1625 3.33731 4.5 3.74981 4.5ZM15.1198 5.85C15.0523 5.505 14.7448 5.25 14.3848 5.25H3.61481C3.25481 5.25 2.94731 5.505 2.87981 5.85L2.12981 9.6C2.03981 10.065 2.39231 10.5 2.86481 10.5H2.99981V14.25C2.99981 14.6625 3.33731 15 3.74981 15H9.74981C10.1623 15 10.4998 14.6625 10.4998 14.25V10.5H13.4998V14.25C13.4998 14.6625 13.8373 15 14.2498 15C14.6623 15 14.9998 14.6625 14.9998 14.25V10.5H15.1348C15.6073 10.5 15.9598 10.065 15.8698 9.6L15.1198 5.85ZM8.99981 13.5H4.49981V10.5H8.99981V13.5Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Foundation
