import { IconSVGProps } from '@/components/ui/icon'

function LockPerson({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0001 7.33329C11.4667 7.33329 11.9134 7.39996 12.3334 7.52663V6.66663C12.3334 5.93329 11.7334 5.33329 11.0001 5.33329H10.3334V3.99996C10.3334 2.15996 8.84008 0.666626 7.00008 0.666626C5.16008 0.666626 3.66675 2.15996 3.66675 3.99996V5.33329H3.00008C2.26675 5.33329 1.66675 5.93329 1.66675 6.66663V13.3333C1.66675 14.0666 2.26675 14.6666 3.00008 14.6666H7.17341C6.64675 13.9133 6.33341 12.9933 6.33341 12C6.33341 9.41996 8.42008 7.33329 11.0001 7.33329ZM4.93341 3.99996C4.93341 2.85996 5.86008 1.93329 7.00008 1.93329C8.14008 1.93329 9.06675 2.85996 9.06675 3.99996V5.33329H4.93341V3.99996Z"
        fill="currentcolor"
      />
      <path
        d="M11.0001 8.66663C9.16008 8.66663 7.66675 10.16 7.66675 12C7.66675 13.84 9.16008 15.3333 11.0001 15.3333C12.8401 15.3333 14.3334 13.84 14.3334 12C14.3334 10.16 12.8401 8.66663 11.0001 8.66663ZM11.0001 9.99996C11.5534 9.99996 12.0001 10.4466 12.0001 11C12.0001 11.5533 11.5534 12 11.0001 12C10.4467 12 10.0001 11.5533 10.0001 11C10.0001 10.4466 10.4467 9.99996 11.0001 9.99996ZM11.0001 14C10.3134 14 9.70675 13.6533 9.34675 13.12C9.83341 12.84 10.3934 12.6666 11.0001 12.6666C11.6067 12.6666 12.1667 12.84 12.6534 13.12C12.2934 13.6533 11.6867 14 11.0001 14Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default LockPerson
