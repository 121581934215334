import { IconSVGProps } from '@/components/ui/icon'

function City({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 8.96861V5.09111C11.25 4.69361 11.0925 4.31111 10.8075 4.03361L9.525 2.75111C9.2325 2.45861 8.76 2.45861 8.4675 2.75111L7.1925 4.02611C6.9075 4.31111 6.75 4.69361 6.75 5.09111V5.96861H3.75C2.925 5.96861 2.25 6.64361 2.25 7.46861V14.9686C2.25 15.7936 2.925 16.4686 3.75 16.4686H14.25C15.075 16.4686 15.75 15.7936 15.75 14.9686V10.4686C15.75 9.64361 15.075 8.96861 14.25 8.96861H11.25ZM5.25 14.9686H3.75V13.4686H5.25V14.9686ZM5.25 11.9686H3.75V10.4686H5.25V11.9686ZM5.25 8.96861H3.75V7.46861H5.25V8.96861ZM9.75 14.9686H8.25V13.4686H9.75V14.9686ZM9.75 11.9686H8.25V10.4686H9.75V11.9686ZM9.75 8.96861H8.25V7.46861H9.75V8.96861ZM9.75 5.96861H8.25V4.46861H9.75V5.96861ZM14.25 14.9686H12.75V13.4686H14.25V14.9686ZM14.25 11.9686H12.75V10.4686H14.25V11.9686Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default City
