import { IconSVGProps } from '@/components/ui/icon'

function KeyboardArrowDown({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.27083 7.5001L10.5042 10.7334L13.7375 7.5001C14.0625 7.1751 14.5875 7.1751 14.9125 7.5001C15.2375 7.8251 15.2375 8.3501 14.9125 8.6751L11.0875 12.5001C10.7625 12.8251 10.2375 12.8251 9.9125 12.5001L6.0875 8.6751C5.7625 8.3501 5.7625 7.8251 6.0875 7.5001C6.4125 7.18343 6.94583 7.1751 7.27083 7.5001Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default KeyboardArrowDown
