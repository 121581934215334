import { IconSVGProps } from '@/components/ui/icon'

function PeapleGroup({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 114 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        d="M17.7227 16.942L39.6342 9.63818L42.0524 29.1316L51.7744 63.2326L67.8132 79.2715L66.3821 82.726H53.9952C53.9952 82.726 50.4419 97.3337 37.1667 97.3337C24.2863 97.3337 21.6707 87.3156 21.6707 87.3156L16.6863 86.1805L15.798 80.4559L5.53314 75.3728L0.647461 60.7651L5.53314 46.1574V31.5497L17.7227 16.942Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.166 81.8869C25.166 81.5007 25.4792 81.1875 25.8655 81.1875H65.0496C65.4359 81.1875 65.7491 81.5007 65.7491 81.8869C65.7491 82.2732 65.4359 82.5864 65.0496 82.5864H25.8655C25.4792 82.5864 25.166 82.2732 25.166 81.8869Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0451 21.169C14.3545 21.4003 14.4178 21.8386 14.1865 22.148L7.02189 31.7332V45.5161C7.02189 45.6057 7.00469 45.6944 6.97123 45.7775L1.44165 59.5044L6.90987 75.8131L15.1016 81.3064C15.4224 81.5215 15.5081 81.956 15.2929 82.2769C15.0778 82.5977 14.6433 82.6834 14.3224 82.4682L5.93289 76.8423C5.8046 76.7563 5.7084 76.6302 5.65929 76.4837L0.0333546 59.7046C-0.0195575 59.5468 -0.01446 59.3753 0.0477312 59.2209L5.62301 45.3805V31.5006C5.62301 31.3497 5.67184 31.2028 5.76222 31.0819L13.0661 21.3105C13.2973 21.0011 13.7357 20.9378 14.0451 21.169Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.9317 6.25509C62.9741 6.63905 62.6972 6.98467 62.3133 7.02705L40.0417 9.48527L22.9967 15.7237C22.6339 15.8564 22.2322 15.67 22.0994 15.3072C21.9667 14.9445 22.1531 14.5428 22.5159 14.41L39.6404 8.14251C39.6933 8.12318 39.7482 8.11029 39.8041 8.10412L62.1598 5.63661C62.5438 5.59423 62.8894 5.87113 62.9317 6.25509Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.766 7.52617C72.8697 7.15403 73.2553 6.93635 73.6275 7.03997L98.7961 14.0477C98.9122 14.08 99.0179 14.1418 99.1031 14.2269L110.306 25.4295C110.418 25.5417 110.489 25.6887 110.506 25.8464L113.319 51.015C113.334 51.1492 113.31 51.2848 113.25 51.4055L108.413 61.0782C108.336 61.232 108.205 61.3521 108.046 61.4155L95.8561 66.2519C95.7594 66.2902 95.6554 66.3065 95.5516 66.2996L87.9915 65.7956L85.3208 76.4317C85.2786 76.5996 85.1757 76.7459 85.0319 76.8423L76.6424 82.4682C76.3215 82.6834 75.887 82.5977 75.6719 82.2769C75.4568 81.956 75.5424 81.5215 75.8633 81.3064L84.0295 75.8302L86.777 64.8885C86.8593 64.5606 87.1645 64.3385 87.5019 64.361L95.487 64.8933L107.282 60.2137L111.906 50.9649L109.143 26.2453L98.2439 15.346L73.2522 8.38759C72.8801 8.28398 72.6624 7.89831 72.766 7.52617Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.1646 37.8814C74.5107 37.71 74.9303 37.8516 75.1018 38.1978L88.0809 64.4028C88.2523 64.7489 88.1107 65.1685 87.7646 65.34C87.4184 65.5114 86.9988 65.3698 86.8273 65.0236L73.8482 38.8186C73.6768 38.4725 73.8184 38.0529 74.1646 37.8814Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.2527 47.616C93.6193 47.7378 93.8177 48.1337 93.6958 48.5003L88.1192 65.2794C87.9974 65.646 87.6015 65.8444 87.2349 65.7226C86.8683 65.6007 86.6699 65.2048 86.7917 64.8382L92.3683 48.0591C92.4902 47.6925 92.8861 47.4941 93.2527 47.616Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.103 47.6566C101.447 47.4811 101.868 47.6178 102.043 47.962L108.41 60.4476C108.585 60.7917 108.448 61.2129 108.104 61.3884C107.76 61.5639 107.339 61.4272 107.163 61.083L100.797 48.5974C100.622 48.2532 100.758 47.832 101.103 47.6566Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M110.436 25.6131C110.608 25.9593 110.466 26.3789 110.12 26.5504L104.543 29.314C104.197 29.4856 103.778 29.344 103.606 28.9979C103.435 28.6518 103.576 28.2322 103.922 28.0606L109.499 25.297C109.845 25.1255 110.265 25.267 110.436 25.6131Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.5527 34.3132C75.5527 33.9269 75.8659 33.6138 76.2522 33.6138H84.6417C85.028 33.6138 85.3412 33.9269 85.3412 34.3132C85.3412 34.6995 85.028 35.0127 84.6417 35.0127H76.2522C75.8659 35.0127 75.5527 34.6995 75.5527 34.3132Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.748 11.2184C68.1291 11.1556 68.489 11.4137 68.5518 11.7949L71.3154 28.574C71.3782 28.9551 71.1201 29.315 70.739 29.3778C70.3578 29.4406 69.9979 29.1825 69.9351 28.8013L67.1715 12.0222C67.1087 11.641 67.3668 11.2812 67.748 11.2184Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.1538 9.67902C64.431 9.94808 64.4376 10.3909 64.1685 10.6681L54.3478 20.7849C54.0787 21.0621 53.6359 21.0686 53.3587 20.7996C53.0816 20.5305 53.075 20.0877 53.3441 19.8105L63.1648 9.69371C63.4338 9.41653 63.8766 9.40995 64.1538 9.67902Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6669 8.13347C40.0346 8.01506 40.4286 8.21714 40.547 8.58484L43.36 17.3198C43.4784 17.6875 43.2763 18.0816 42.9086 18.2C42.5409 18.3184 42.1469 18.1163 42.0285 17.7486L39.2155 9.01364C39.0971 8.64595 39.2992 8.25188 39.6669 8.13347Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.1938 14.3409C99.404 14.665 99.3117 15.0981 98.9876 15.3083L75.2501 30.7056C74.926 30.9158 74.4929 30.8235 74.2826 30.4994C74.0724 30.1754 74.1647 29.7422 74.4888 29.532L98.2263 14.1347C98.5504 13.9245 98.9835 14.0168 99.1938 14.3409Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.6256 29.4365C67.9321 29.4365 65.7485 31.6201 65.7485 34.3136C65.7485 37.0072 67.9321 39.1908 70.6256 39.1908C73.3192 39.1908 75.5028 37.0072 75.5028 34.3136C75.5028 31.6201 73.3192 29.4365 70.6256 29.4365ZM64.3496 34.3136C64.3496 30.8475 67.1595 28.0376 70.6256 28.0376C74.0918 28.0376 76.9017 30.8475 76.9017 34.3136C76.9017 37.7798 74.0918 40.5897 70.6256 40.5897C67.1595 40.5897 64.3496 37.7798 64.3496 34.3136Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.6256 77.0102C67.9321 77.0102 65.7485 79.1938 65.7485 81.8874C65.7485 84.5809 67.9321 86.7645 70.6256 86.7645C73.3192 86.7645 75.5028 84.5809 75.5028 81.8874C75.5028 79.1938 73.3192 77.0102 70.6256 77.0102ZM64.3496 81.8874C64.3496 78.4212 67.1595 75.6113 70.6256 75.6113C74.0918 75.6113 76.9017 78.4212 76.9017 81.8874C76.9017 85.3535 74.0918 88.1634 70.6256 88.1634C67.1595 88.1634 64.3496 85.3535 64.3496 81.8874Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.862 1.45455C65.1684 1.45455 62.9848 3.63812 62.9848 6.33169C62.9848 9.02527 65.1684 11.2088 67.862 11.2088C70.5555 11.2088 72.7391 9.02527 72.7391 6.33169C72.7391 3.63812 70.5555 1.45455 67.862 1.45455ZM61.5859 6.33169C61.5859 2.86554 64.3958 0.0556641 67.862 0.0556641C71.3281 0.0556641 74.138 2.86554 74.138 6.33169C74.138 9.79785 71.3281 12.6077 67.862 12.6077C64.3958 12.6077 61.5859 9.79785 61.5859 6.33169Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2897 77.0102C17.5961 77.0102 15.4126 79.1938 15.4126 81.8874C15.4126 84.5809 17.5961 86.7645 20.2897 86.7645C22.9833 86.7645 25.1668 84.5809 25.1668 81.8874C25.1668 79.1938 22.9833 77.0102 20.2897 77.0102ZM14.0137 81.8874C14.0137 78.4212 16.8235 75.6113 20.2897 75.6113C23.7559 75.6113 26.5657 78.4212 26.5657 81.8874C26.5657 85.3535 23.7559 88.1634 20.2897 88.1634C16.8235 88.1634 14.0137 85.3535 14.0137 81.8874Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8659 43.4023C23.1723 43.4023 20.9887 45.5859 20.9887 48.2794C20.9887 50.973 23.1723 53.1566 25.8659 53.1566C28.5594 53.1566 30.743 50.973 30.743 48.2794C30.743 45.5859 28.5594 43.4023 25.8659 43.4023ZM19.5898 48.2794C19.5898 44.8133 22.3997 42.0034 25.8659 42.0034C29.332 42.0034 32.1419 44.8133 32.1419 48.2794C32.1419 51.7456 29.332 54.5555 25.8659 54.5555C22.3997 54.5555 19.5898 51.7456 19.5898 48.2794Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.98 70.1885C59.2542 69.9163 59.697 69.9179 59.9692 70.192L66.9276 77.1997C67.1998 77.4738 67.1982 77.9167 66.9241 78.1889C66.65 78.461 66.2071 78.4595 65.935 78.1854L58.9766 71.1776C58.7044 70.9035 58.7059 70.4607 58.98 70.1885Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.107 37.8676C67.4608 38.0226 67.6219 38.4351 67.4669 38.789L60.1137 55.5681C59.9586 55.9219 59.5461 56.083 59.1923 55.9279C58.8385 55.7729 58.6774 55.3604 58.8324 55.0066L66.1856 38.2275C66.3407 37.8737 66.7532 37.7125 67.107 37.8676Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8007 39.5418C47.1839 39.493 47.534 39.7641 47.5828 40.1473L48.9646 51.0044C49.0134 51.3876 48.7423 51.7378 48.3591 51.7866C47.9759 51.8353 47.6257 51.5642 47.5769 51.181L46.1951 40.324C46.1464 39.9408 46.4175 39.5906 46.8007 39.5418Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.9974 29.8962C56.1202 29.53 56.5167 29.3326 56.8829 29.4554L65.2725 32.2684C65.6387 32.3912 65.8361 32.7876 65.7133 33.1539C65.5905 33.5201 65.194 33.7175 64.8278 33.5947L56.4382 30.7817C56.072 30.6589 55.8746 30.2625 55.9974 29.8962Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.6252 39.1904C71.0115 39.1904 71.3247 39.5036 71.3247 39.8899V76.2611C71.3247 76.6474 71.0115 76.9605 70.6252 76.9605C70.2389 76.9605 69.9258 76.6474 69.9258 76.2611V39.8899C69.9258 39.5036 70.2389 39.1904 70.6252 39.1904Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.8477 81.1875C54.234 81.1875 54.5471 81.5007 54.5471 81.8869C54.5471 86.1753 52.2566 89.8537 48.9618 92.437C45.6697 95.0181 41.3234 96.5525 37.0686 96.5525C36.6823 96.5525 36.3691 96.2394 36.3691 95.8531C36.3691 95.4668 36.6823 95.1536 37.0686 95.1536C41.0059 95.1536 45.0492 93.727 48.0986 91.3361C51.1454 88.9473 53.1483 85.6427 53.1483 81.8869C53.1483 81.5007 53.4614 81.1875 53.8477 81.1875Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0993 87.3418C23.0416 87.0136 22.7551 86.7642 22.4104 86.7642C22.0241 86.7642 21.7109 87.0773 21.7109 87.4636H22.4104C21.7109 87.4636 21.7109 87.4646 21.7109 87.4657L21.7109 87.4677L21.711 87.4718L21.7111 87.48L21.7117 87.4966C21.7122 87.5077 21.713 87.5188 21.714 87.53C21.716 87.5525 21.719 87.5753 21.7229 87.5986C21.7309 87.6449 21.7428 87.6939 21.7591 87.746C21.7915 87.8494 21.8435 87.9724 21.9241 88.1222C22.0842 88.4195 22.3753 88.8547 22.9112 89.4927L22.9111 89.4927L22.9166 89.4991C26.364 93.5041 31.2314 96.5526 37.0674 96.5526C37.4537 96.5526 37.7669 96.2394 37.7669 95.8532C37.7669 95.4669 37.4537 95.1537 37.0674 95.1537C31.7518 95.1537 27.2437 92.3804 23.9796 88.5897C23.481 87.9959 23.2546 87.6424 23.1558 87.4589C23.1316 87.414 23.1161 87.3812 23.1065 87.3592C23.1035 87.3522 23.1011 87.3464 23.0993 87.3418Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8823 68.9796C42.0607 69.3223 41.9275 69.7446 41.5849 69.923L24.8058 78.658C24.4631 78.8364 24.0408 78.7032 23.8624 78.3606C23.684 78.018 23.8172 77.5956 24.1598 77.4172L40.9389 68.6822C41.2816 68.5038 41.7039 68.637 41.8823 68.9796Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6599 49.8975C30.9382 49.6297 31.381 49.6383 31.6488 49.9167L40.3838 58.9972C40.6516 59.2755 40.6431 59.7183 40.3647 59.9861C40.0863 60.2539 39.6435 60.2453 39.3757 59.967L30.6407 50.8865C30.3729 50.6081 30.3815 50.1653 30.6599 49.8975Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4561 18.9932C22.6044 18.6365 23.0137 18.4675 23.3704 18.6158L35.9548 23.8469C36.3115 23.9952 36.4804 24.4045 36.3322 24.7612C36.1839 25.1179 35.7745 25.2869 35.4178 25.1386L22.8335 19.9075C22.4768 19.7592 22.3078 19.3499 22.4561 18.9932Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6279 36.7076C37.8591 37.0171 37.7956 37.4554 37.4862 37.6866L29.0966 43.9541C28.7871 44.1853 28.3488 44.1218 28.1177 43.8123C27.8865 43.5029 27.9499 43.0646 28.2594 42.8334L36.6489 36.5659C36.9584 36.3347 37.3967 36.3982 37.6279 36.7076Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5133 52.6492C23.8256 52.8765 23.8945 53.314 23.6671 53.6263L6.88799 76.6729C6.66063 76.9852 6.22315 77.0541 5.91086 76.8267C5.59857 76.5993 5.52972 76.1618 5.75708 75.8496L22.5362 52.8029C22.7636 52.4907 23.201 52.4218 23.5133 52.6492Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9586 48.9437C21.1369 49.2865 21.0035 49.7088 20.6608 49.887L1.0194 60.1025C0.676685 60.2808 0.254369 60.1474 0.0761254 59.8047C-0.102118 59.462 0.0312081 59.0397 0.373918 58.8615L20.0153 48.6459C20.3581 48.4677 20.7804 48.601 20.9586 48.9437Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.77098 31.0701C6.00853 30.7655 6.44805 30.7112 6.75266 30.9487L22.4461 43.1876C22.7507 43.4251 22.805 43.8647 22.5675 44.1693C22.3299 44.4739 21.8904 44.5282 21.5858 44.2907L5.89238 32.0518C5.58777 31.8142 5.53342 31.3747 5.77098 31.0701Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.8434 35.0468C94.695 35.0468 93.7641 35.9777 93.7641 37.1261C93.7641 38.2745 94.695 39.2054 95.8434 39.2054C96.9918 39.2054 97.9227 38.2745 97.9227 37.1261C97.9227 35.9777 96.9918 35.0468 95.8434 35.0468ZM86.7695 37.1261C86.7695 32.1147 90.832 28.0522 95.8434 28.0522C100.855 28.0522 104.917 32.1147 104.917 37.1261C104.917 42.1374 100.855 46.1999 95.8434 46.1999C90.832 46.1999 86.7695 42.1374 86.7695 37.1261Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.0836 60.2157C49.9352 60.2157 49.0043 61.1467 49.0043 62.295C49.0043 63.4434 49.9352 64.3744 51.0836 64.3744C52.232 64.3744 53.1629 63.4434 53.1629 62.295C53.1629 61.1467 52.232 60.2157 51.0836 60.2157ZM42.0098 62.295C42.0098 57.2837 46.0723 53.2212 51.0836 53.2212C56.095 53.2212 60.1575 57.2837 60.1575 62.295C60.1575 67.3064 56.095 71.3689 51.0836 71.3689C46.0723 71.3689 42.0098 67.3064 42.0098 62.295Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4752 12.6572C14.7817 12.6572 12.5981 14.8408 12.5981 17.5343C12.5981 20.2279 14.7817 22.4115 17.4752 22.4115C20.1688 22.4115 22.3524 20.2279 22.3524 17.5343C22.3524 14.8408 20.1688 12.6572 17.4752 12.6572ZM11.1992 17.5343C11.1992 14.0682 14.0091 11.2583 17.4752 11.2583C20.9414 11.2583 23.7513 14.0682 23.7513 17.5343C23.7513 21.0005 20.9414 23.8104 17.4752 23.8104C14.0091 23.8104 11.1992 21.0005 11.1992 17.5343Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.4567 26.6083C44.3083 26.6083 43.3773 27.5392 43.3773 28.6876C43.3773 29.836 44.3083 30.7669 45.4567 30.7669C46.605 30.7669 47.536 29.836 47.536 28.6876C47.536 27.5392 46.605 26.6083 45.4567 26.6083ZM36.3828 28.6876C36.3828 23.6763 40.4453 19.6138 45.4567 19.6138C50.468 19.6138 54.5305 23.6763 54.5305 28.6876C54.5305 33.699 50.468 37.7615 45.4567 37.7615C40.4453 37.7615 36.3828 33.699 36.3828 28.6876Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default PeapleGroup
