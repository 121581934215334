import { IconSVGProps } from '@/components/ui/icon'

function AddCircle({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00033 1.1665C3.78033 1.1665 1.16699 3.77984 1.16699 6.99984C1.16699 10.2198 3.78033 12.8332 7.00033 12.8332C10.2203 12.8332 12.8337 10.2198 12.8337 6.99984C12.8337 3.77984 10.2203 1.1665 7.00033 1.1665ZM9.33366 7.58317H7.58366V9.33317C7.58366 9.654 7.32116 9.9165 7.00033 9.9165C6.67949 9.9165 6.41699 9.654 6.41699 9.33317V7.58317H4.66699C4.34616 7.58317 4.08366 7.32067 4.08366 6.99984C4.08366 6.679 4.34616 6.4165 4.66699 6.4165H6.41699V4.6665C6.41699 4.34567 6.67949 4.08317 7.00033 4.08317C7.32116 4.08317 7.58366 4.34567 7.58366 4.6665V6.4165H9.33366C9.65449 6.4165 9.91699 6.679 9.91699 6.99984C9.91699 7.32067 9.65449 7.58317 9.33366 7.58317Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AddCircle
