import { IconSVGProps } from '@/components/ui/icon'

function Label({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4234 2.75H5.1734C4.6559 2.75 4.2509 3.0125 3.9809 3.41L0.200898 9.0875C0.0358984 9.3425 0.0358984 9.665 0.200898 9.92L3.9809 15.59C4.2509 15.98 4.7084 16.25 5.2259 16.25H16.4234C17.2484 16.25 17.9234 15.575 17.9234 14.75V4.25C17.9234 3.425 17.2484 2.75 16.4234 2.75ZM6.6734 10.625C6.0509 10.625 5.5484 10.1225 5.5484 9.5C5.5484 8.8775 6.0509 8.375 6.6734 8.375C7.2959 8.375 7.7984 8.8775 7.7984 9.5C7.7984 10.1225 7.2959 10.625 6.6734 10.625ZM10.4234 10.625C9.8009 10.625 9.2984 10.1225 9.2984 9.5C9.2984 8.8775 9.8009 8.375 10.4234 8.375C11.0459 8.375 11.5484 8.8775 11.5484 9.5C11.5484 10.1225 11.0459 10.625 10.4234 10.625ZM14.1734 10.625C13.5509 10.625 13.0484 10.1225 13.0484 9.5C13.0484 8.8775 13.5509 8.375 14.1734 8.375C14.7959 8.375 15.2984 8.8775 15.2984 9.5C15.2984 10.1225 14.7959 10.625 14.1734 10.625Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Label
