import { IconSVGProps } from '@/components/ui/icon'

function Satisfied({ size }: IconSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 120 118"
      fill="none"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.3445 16.6559C35.7827 16.6559 16.6821 35.7565 16.6821 59.3183C16.6821 82.8801 35.7827 101.981 59.3445 101.981C82.9062 101.981 102.007 82.8801 102.007 59.3183C102.007 35.7565 82.9062 16.6559 59.3445 16.6559ZM12.4854 59.3183C12.4854 33.4387 33.4649 12.4592 59.3445 12.4592C85.224 12.4592 106.204 33.4387 106.204 59.3183C106.204 85.1979 85.224 106.177 59.3445 106.177C33.4649 106.177 12.4854 85.1979 12.4854 59.3183Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.3446 4.0544C28.8232 4.0544 4.08077 28.7968 4.08077 59.3182C4.08077 89.8396 28.8232 114.582 59.3446 114.582C89.866 114.582 114.608 89.8396 114.608 59.3182C114.608 28.7968 89.866 4.0544 59.3446 4.0544ZM2.68188 59.3182C2.68188 28.0243 28.0506 2.65552 59.3446 2.65552C90.6385 2.65552 116.007 28.0243 116.007 59.3182C116.007 90.6122 90.6385 115.981 59.3446 115.981C28.0506 115.981 2.68188 90.6122 2.68188 59.3182Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5655 67.0084C42.9518 67.0084 43.265 67.3216 43.265 67.7079C43.265 76.5994 50.4531 83.7875 59.3446 83.7875C68.2362 83.7875 75.4243 76.5994 75.4243 67.7079C75.4243 67.3216 75.7375 67.0084 76.1238 67.0084C76.51 67.0084 76.8232 67.3216 76.8232 67.7079C76.8232 77.372 69.0088 85.1864 59.3446 85.1864C49.6805 85.1864 41.8661 77.372 41.8661 67.7079C41.8661 67.3216 42.1792 67.0084 42.5655 67.0084Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.0742 48.7658C73.4048 48.7658 71.1971 50.9655 71.1971 53.6923C71.1971 54.0786 70.884 54.3918 70.4977 54.3918C70.1114 54.3918 69.7982 54.0786 69.7982 53.6923C69.7982 50.201 72.6242 47.3669 76.0742 47.3669C79.5776 47.3669 82.3503 50.205 82.3503 53.6923C82.3503 54.0786 82.0371 54.3918 81.6508 54.3918C81.2645 54.3918 80.9514 54.0786 80.9514 53.6923C80.9514 50.9615 78.789 48.7658 76.0742 48.7658Z"
        fill="#E85D0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5158 48.7658C39.8463 48.7658 37.6386 50.9655 37.6386 53.6923C37.6386 54.0786 37.3255 54.3918 36.9392 54.3918C36.5529 54.3918 36.2397 54.0786 36.2397 53.6923C36.2397 50.201 39.0658 47.3669 42.5158 47.3669C46.0191 47.3669 48.7918 50.205 48.7918 53.6923C48.7918 54.0786 48.4787 54.3918 48.0924 54.3918C47.7061 54.3918 47.3929 54.0786 47.3929 53.6923C47.3929 50.9615 45.2305 48.7658 42.5158 48.7658Z"
        fill="#E85D0C"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.1345 30.1575C14.0302 46.2618 14.0302 72.4239 30.1345 88.5281C46.2387 104.632 72.4008 104.632 88.5051 88.5281C104.609 72.4239 104.609 46.2618 88.5051 30.1575L93.4509 25.2117C112.287 44.0475 112.287 74.6382 93.4509 93.474C74.6152 112.31 44.0244 112.31 25.1886 93.474C6.35282 74.6382 6.35282 44.0475 25.1886 25.2117L30.1345 30.1575Z"
        fill="#E85D0C"
      />
    </svg>
  )
}

export default Satisfied
