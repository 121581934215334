import { IconSVGProps } from '@/components/ui/icon'

function Number({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5125 7.4525L15.52 7.4075C15.64 6.9425 15.295 6.5 14.8225 6.5H12.7525L13.2775 4.4075C13.39 3.9425 13.045 3.5 12.5725 3.5C12.235 3.5 11.95 3.725 11.8675 4.0475L11.2525 6.5H8.25246L8.77746 4.4075C8.88996 3.9425 8.54496 3.5 8.07246 3.5C7.73496 3.5 7.44996 3.725 7.36746 4.0475L6.75246 6.5H4.32246C3.98496 6.5 3.69246 6.725 3.61746 7.0475L3.60246 7.0925C3.48996 7.5575 3.83496 8 4.30746 8H6.37746L5.62746 11H3.19746C2.85996 11 2.57496 11.225 2.49246 11.5475L2.47746 11.5925C2.36496 12.0575 2.70996 12.5 3.18246 12.5H5.25246L4.72746 14.5925C4.61496 15.0575 4.95996 15.5 5.43246 15.5C5.76996 15.5 6.05496 15.275 6.13746 14.9525L6.75246 12.5H9.75246L9.22746 14.5925C9.11496 15.0575 9.45996 15.5 9.93246 15.5C10.27 15.5 10.555 15.275 10.6375 14.9525L11.2525 12.5H13.6825C14.02 12.5 14.305 12.275 14.3875 11.9525L14.395 11.9075C14.5075 11.45 14.1625 11 13.69 11H11.6275L12.3775 8H14.8075C15.145 8 15.4375 7.775 15.5125 7.4525ZM10.1275 11H7.12746L7.87746 8H10.8775L10.1275 11Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Number
