import { IconSVGProps } from '@/components/ui/icon'

function KeyboardArrowRight({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499609 8.22917L3.73294 4.99583L0.499609 1.7625C0.174609 1.4375 0.174609 0.9125 0.499609 0.5875C0.824609 0.2625 1.34961 0.2625 1.67461 0.5875L5.49961 4.4125C5.82461 4.7375 5.82461 5.2625 5.49961 5.5875L1.67461 9.4125C1.34961 9.7375 0.824609 9.7375 0.499609 9.4125C0.182943 9.0875 0.174609 8.55417 0.499609 8.22917Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default KeyboardArrowRight
