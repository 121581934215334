import { IconSVGProps } from '@/components/ui/icon'

function Calendar({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3335 1.99984H12.6668V1.33317C12.6668 0.966504 12.3668 0.666504 12.0002 0.666504C11.6335 0.666504 11.3335 0.966504 11.3335 1.33317V1.99984H4.66683V1.33317C4.66683 0.966504 4.36683 0.666504 4.00016 0.666504C3.6335 0.666504 3.3335 0.966504 3.3335 1.33317V1.99984H2.66683C1.9335 1.99984 1.3335 2.59984 1.3335 3.33317V13.9998C1.3335 14.7332 1.9335 15.3332 2.66683 15.3332H13.3335C14.0668 15.3332 14.6668 14.7332 14.6668 13.9998V3.33317C14.6668 2.59984 14.0668 1.99984 13.3335 1.99984ZM12.6668 13.9998H3.3335C2.96683 13.9998 2.66683 13.6998 2.66683 13.3332V5.33317H13.3335V13.3332C13.3335 13.6998 13.0335 13.9998 12.6668 13.9998Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Calendar
