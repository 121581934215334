import { IconSVGProps } from '@/components/ui/icon'

function AppRegistration({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 6C9.45343 6 10.125 5.32843 10.125 4.5C10.125 3.67157 9.45343 3 8.625 3C7.79657 3 7.125 3.67157 7.125 4.5C7.125 5.32843 7.79657 6 8.625 6Z"
        fill="currentcolor"
      />
      <path
        d="M4.125 15C4.95343 15 5.625 14.3284 5.625 13.5C5.625 12.6716 4.95343 12 4.125 12C3.29657 12 2.625 12.6716 2.625 13.5C2.625 14.3284 3.29657 15 4.125 15Z"
        fill="currentcolor"
      />
      <path
        d="M4.125 10.5C4.95343 10.5 5.625 9.82843 5.625 9C5.625 8.17157 4.95343 7.5 4.125 7.5C3.29657 7.5 2.625 8.17157 2.625 9C2.625 9.82843 3.29657 10.5 4.125 10.5Z"
        fill="currentcolor"
      />
      <path
        d="M4.125 6C4.95343 6 5.625 5.32843 5.625 4.5C5.625 3.67157 4.95343 3 4.125 3C3.29657 3 2.625 3.67157 2.625 4.5C2.625 5.32843 3.29657 6 4.125 6Z"
        fill="currentcolor"
      />
      <path
        d="M13.125 6C13.9534 6 14.625 5.32843 14.625 4.5C14.625 3.67157 13.9534 3 13.125 3C12.2966 3 11.625 3.67157 11.625 4.5C11.625 5.32843 12.2966 6 13.125 6Z"
        fill="currentcolor"
      />
      <path
        d="M7.875 13.5525V14.625C7.875 14.835 8.04 15 8.25 15H9.3C9.3975 15 9.495 14.9625 9.5625 14.8875L13.935 10.515L12.345 8.925L7.9875 13.2825C7.9125 13.3575 7.875 13.455 7.875 13.5525Z"
        fill="currentcolor"
      />
      <path
        d="M8.6475 10.5L10.125 9.0225V9C10.125 8.175 9.45 7.5 8.625 7.5C7.8 7.5 7.125 8.175 7.125 9C7.125 9.825 7.8 10.5 8.625 10.5H8.6475Z"
        fill="currentcolor"
      />
      <path
        d="M15.2625 8.67L14.205 7.6125C14.055 7.4625 13.8225 7.4625 13.6725 7.6125L12.8775 8.4075L14.4675 9.9975L15.2625 9.2025C15.4125 9.0525 15.4125 8.82 15.2625 8.67Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AppRegistration
