import { IconSVGProps } from '@/components/ui/icon'

function Search({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9824 10.8537H11.3899L11.1799 10.6512C12.0799 9.60116 12.5449 8.16866 12.2899 6.64616C11.9374 4.56116 10.1974 2.89616 8.0974 2.64116C4.9249 2.25116 2.2549 4.92116 2.6449 8.09366C2.8999 10.1937 4.5649 11.9337 6.6499 12.2862C8.1724 12.5412 9.6049 12.0762 10.6549 11.1762L10.8574 11.3862V11.9787L14.0449 15.1662C14.3524 15.4737 14.8549 15.4737 15.1624 15.1662C15.4699 14.8587 15.4699 14.3562 15.1624 14.0487L11.9824 10.8537ZM7.4824 10.8537C5.6149 10.8537 4.1074 9.34616 4.1074 7.47866C4.1074 5.61116 5.6149 4.10366 7.4824 4.10366C9.3499 4.10366 10.8574 5.61116 10.8574 7.47866C10.8574 9.34616 9.3499 10.8537 7.4824 10.8537Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Search
