import { IconSVGProps } from '@/components/ui/icon'

function AttachFile({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1663 4.49831V11.5516C11.1663 12.945 10.1463 14.185 8.75967 14.3183C7.16634 14.4716 5.83301 13.225 5.83301 11.665V3.42497C5.83301 2.55164 6.45967 1.75831 7.32634 1.67164C8.32634 1.57164 9.16634 2.35164 9.16634 3.33164V10.3316C9.16634 10.6983 8.86634 10.9983 8.49967 10.9983C8.13301 10.9983 7.83301 10.6983 7.83301 10.3316V4.49831C7.83301 4.22497 7.60634 3.99831 7.33301 3.99831C7.05967 3.99831 6.83301 4.22497 6.83301 4.49831V10.2383C6.83301 11.1116 7.45967 11.905 8.32634 11.9916C9.32634 12.0916 10.1663 11.3116 10.1663 10.3316V3.44497C10.1663 2.05164 9.14634 0.81164 7.75967 0.678307C6.17301 0.524974 4.83301 1.77164 4.83301 3.33164V11.5116C4.83301 13.425 6.23301 15.1383 8.13967 15.3183C10.333 15.5183 12.1663 13.8116 12.1663 11.665V4.49831C12.1663 4.22497 11.9397 3.99831 11.6663 3.99831C11.393 3.99831 11.1663 4.22497 11.1663 4.49831Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AttachFile
