import { IconSVGProps } from '@/components/ui/icon'

function Chart({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 11.5202L7.59333 8.41354C7.81333 8.10687 8.24667 8.04687 8.54 8.28021L14 12.5202V12.8535C14 13.2202 13.7 13.5202 13.3333 13.5202H2.66667C2.3 13.5202 2 13.2202 2 12.8535V9.0402C2.14667 9.0402 2.29333 9.08687 2.41333 9.18687L5.33333 11.5202ZM2 7.52021C2.29333 7.52021 2.58667 7.6202 2.83333 7.81354L5.08 9.60687L6.92667 7.06687C7.36667 6.4602 8.22667 6.33354 8.82667 6.8002L14 10.8335V5.52021C14 5.15354 13.7 4.85354 13.3333 4.85354H11.3333L8.54667 2.62687C8.24667 2.38687 7.81333 2.44687 7.58667 2.7602L4.66667 6.85354L2.4 5.15354C2.28 5.06687 2.14 5.0202 2 5.0202V7.52021Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Chart
