import { IconSVGProps } from '@/components/ui/icon'

function Add({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0001 10.8333H10.8334V15C10.8334 15.4583 10.4584 15.8333 10.0001 15.8333C9.54175 15.8333 9.16675 15.4583 9.16675 15V10.8333H5.00008C4.54175 10.8333 4.16675 10.4583 4.16675 10C4.16675 9.54166 4.54175 9.16666 5.00008 9.16666H9.16675V5C9.16675 4.54166 9.54175 4.16666 10.0001 4.16666C10.4584 4.16666 10.8334 4.54166 10.8334 5V9.16666H15.0001C15.4584 9.16666 15.8334 9.54166 15.8334 10C15.8334 10.4583 15.4584 10.8333 15.0001 10.8333Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Add
