import { IconSVGProps } from '@/components/ui/icon'

function Cellphone({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.25H6C4.965 1.25 4.125 2.09 4.125 3.125V15.875C4.125 16.91 4.965 17.75 6 17.75H12C13.035 17.75 13.875 16.91 13.875 15.875V3.125C13.875 2.09 13.035 1.25 12 1.25ZM9 17C8.3775 17 7.875 16.4975 7.875 15.875C7.875 15.2525 8.3775 14.75 9 14.75C9.6225 14.75 10.125 15.2525 10.125 15.875C10.125 16.4975 9.6225 17 9 17ZM12.375 14H5.625V3.5H12.375V14Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Cellphone
