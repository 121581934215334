import { IconSVGProps } from '@/components/ui/icon'

function Phone({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4164 11.9512L12.5114 11.7337C12.0539 11.6812 11.6039 11.8387 11.2814 12.1612L9.90143 13.5412C7.77893 12.4612 6.03893 10.7287 4.95893 8.59867L6.34643 7.21117C6.66893 6.88867 6.82643 6.43867 6.77393 5.98117L6.55643 4.09117C6.46643 3.33367 5.82893 2.76367 5.06393 2.76367H3.76643C2.91893 2.76367 2.21393 3.46867 2.26643 4.31617C2.66393 10.7212 7.78643 15.8362 14.1839 16.2337C15.0314 16.2862 15.7364 15.5812 15.7364 14.7337V13.4362C15.7439 12.6787 15.1739 12.0412 14.4164 11.9512Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Phone
