import { IconSVGProps } from '@/components/ui/icon'

function LocationSearching({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.705 8.25C15.36 5.1225 12.8775 2.64 9.75 2.295V1.5C9.75 1.0875 9.4125 0.75 9 0.75C8.5875 0.75 8.25 1.0875 8.25 1.5V2.295C5.1225 2.64 2.64 5.1225 2.295 8.25H1.5C1.0875 8.25 0.75 8.5875 0.75 9C0.75 9.4125 1.0875 9.75 1.5 9.75H2.295C2.64 12.8775 5.1225 15.36 8.25 15.705V16.5C8.25 16.9125 8.5875 17.25 9 17.25C9.4125 17.25 9.75 16.9125 9.75 16.5V15.705C12.8775 15.36 15.36 12.8775 15.705 9.75H16.5C16.9125 9.75 17.25 9.4125 17.25 9C17.25 8.5875 16.9125 8.25 16.5 8.25H15.705ZM9 14.25C6.0975 14.25 3.75 11.9025 3.75 9C3.75 6.0975 6.0975 3.75 9 3.75C11.9025 3.75 14.25 6.0975 14.25 9C14.25 11.9025 11.9025 14.25 9 14.25Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default LocationSearching
