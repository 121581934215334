import { IconSVGProps } from '@/components/ui/icon'

function Medal({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.28033 8.29557L8.00033 7.74891L8.71366 8.28891C8.97366 8.48224 9.32699 8.23557 9.23366 7.92224L8.95366 7.01557L9.75366 6.38224C10.0003 6.19557 9.86033 5.79557 9.54033 5.79557H8.60699L8.32032 4.90224C8.22032 4.59557 7.78699 4.59557 7.68699 4.90224L7.39366 5.79557H6.45366C6.14033 5.79557 6.00033 6.19557 6.24699 6.38891L7.04033 7.02224L6.76033 7.92891C6.66699 8.24224 7.02033 8.48891 7.28033 8.29557ZM4.00033 14.2022C4.00033 14.6556 4.44699 14.9756 4.88033 14.8356L8.00033 13.7956L11.1203 14.8356C11.5537 14.9822 12.0003 14.6622 12.0003 14.2022V9.98224C12.827 9.04224 13.3337 7.81557 13.3337 6.46224C13.3337 3.51557 10.947 1.12891 8.00033 1.12891C5.05366 1.12891 2.66699 3.51557 2.66699 6.46224C2.66699 7.81557 3.17366 9.04224 4.00033 9.98224V14.2022ZM8.00033 2.46224C10.207 2.46224 12.0003 4.25557 12.0003 6.46224C12.0003 8.66891 10.207 10.4622 8.00033 10.4622C5.79366 10.4622 4.00033 8.66891 4.00033 6.46224C4.00033 4.25557 5.79366 2.46224 8.00033 2.46224Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Medal
