import { IconSVGProps } from '@/components/ui/icon'

function Warning({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35259 15.3777H14.6476C15.8026 15.3777 16.5226 14.1252 15.9451 13.1277L10.2976 3.3702C9.72009 2.3727 8.28009 2.3727 7.70259 3.3702L2.05509 13.1277C1.47759 14.1252 2.19759 15.3777 3.35259 15.3777ZM9.00009 10.1277C8.58759 10.1277 8.25009 9.7902 8.25009 9.3777V7.8777C8.25009 7.4652 8.58759 7.1277 9.00009 7.1277C9.41259 7.1277 9.75009 7.4652 9.75009 7.8777V9.3777C9.75009 9.7902 9.41259 10.1277 9.00009 10.1277ZM9.75009 13.1277H8.25009V11.6277H9.75009V13.1277Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Warning
