import { IconSVGProps } from '@/components/ui/icon'

function File({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1583 2.15829C11.8417 1.84163 11.4167 1.66663 10.975 1.66663H4.99999C4.08333 1.66663 3.33333 2.41663 3.33333 3.33329V16.6666C3.33333 17.5833 4.07499 18.3333 4.99166 18.3333H15C15.9167 18.3333 16.6667 17.5833 16.6667 16.6666V7.35829C16.6667 6.91663 16.4917 6.49163 16.175 6.18329L12.1583 2.15829ZM12.5 15H7.49999C7.04166 15 6.66666 14.625 6.66666 14.1666C6.66666 13.7083 7.04166 13.3333 7.49999 13.3333H12.5C12.9583 13.3333 13.3333 13.7083 13.3333 14.1666C13.3333 14.625 12.9583 15 12.5 15ZM12.5 11.6666H7.49999C7.04166 11.6666 6.66666 11.2916 6.66666 10.8333C6.66666 10.375 7.04166 9.99996 7.49999 9.99996H12.5C12.9583 9.99996 13.3333 10.375 13.3333 10.8333C13.3333 11.2916 12.9583 11.6666 12.5 11.6666ZM10.8333 6.66663V2.91663L15.4167 7.49996H11.6667C11.2083 7.49996 10.8333 7.12496 10.8333 6.66663Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default File
