import { IconSVGProps } from '@/components/ui/icon'

function More({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4224 2.25H5.17242C4.65492 2.25 4.24992 2.5125 3.97992 2.91L0.199922 8.5875C0.0349219 8.8425 0.0349219 9.165 0.199922 9.42L3.97992 15.09C4.24992 15.48 4.70742 15.75 5.22492 15.75H16.4224C17.2474 15.75 17.9224 15.075 17.9224 14.25V3.75C17.9224 2.925 17.2474 2.25 16.4224 2.25ZM6.67242 10.125C6.04992 10.125 5.54742 9.6225 5.54742 9C5.54742 8.3775 6.04992 7.875 6.67242 7.875C7.29492 7.875 7.79742 8.3775 7.79742 9C7.79742 9.6225 7.29492 10.125 6.67242 10.125ZM10.4224 10.125C9.79992 10.125 9.29742 9.6225 9.29742 9C9.29742 8.3775 9.79992 7.875 10.4224 7.875C11.0449 7.875 11.5474 8.3775 11.5474 9C11.5474 9.6225 11.0449 10.125 10.4224 10.125ZM14.1724 10.125C13.5499 10.125 13.0474 9.6225 13.0474 9C13.0474 8.3775 13.5499 7.875 14.1724 7.875C14.7949 7.875 15.2974 8.3775 15.2974 9C15.2974 9.6225 14.7949 10.125 14.1724 10.125Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default More
