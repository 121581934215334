import { IconSVGProps } from '@/components/ui/icon'

function Hub({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1463_12400)">
        <path
          d="M6.1 12.4666C6.35333 12.7999 6.5 13.2133 6.5 13.6666C6.5 14.7733 5.60667 15.6666 4.5 15.6666C3.39333 15.6666 2.5 14.7733 2.5 13.6666C2.5 12.5599 3.39333 11.6666 4.5 11.6666C4.79333 11.6666 5.06667 11.7266 5.32 11.8399L6.26 10.6599C5.64667 9.97325 5.4 9.06659 5.53333 8.19992L4.18 7.74659C3.82 8.29992 3.20667 8.66659 2.5 8.66659C1.39333 8.66659 0.5 7.77325 0.5 6.66659C0.5 5.55992 1.39333 4.66659 2.5 4.66659C3.60667 4.66659 4.5 5.55992 4.5 6.66659C4.5 6.71325 4.5 6.75992 4.49333 6.80659L5.84667 7.25992C6.27333 6.45325 7.06 5.86659 7.99333 5.71325V4.27325C7.14 4.04659 6.5 3.26659 6.5 2.33325C6.5 1.22659 7.39333 0.333252 8.5 0.333252C9.60667 0.333252 10.5 1.22659 10.5 2.33325C10.5 3.26659 9.86 4.04659 9 4.27325V5.71325C9.93333 5.86659 10.72 6.45325 11.1467 7.25992L12.5 6.80659C12.5 6.75992 12.5 6.71325 12.5 6.66659C12.5 5.55992 13.3933 4.66659 14.5 4.66659C15.6067 4.66659 16.5 5.55992 16.5 6.66659C16.5 7.77325 15.6067 8.66659 14.5 8.66659C13.7933 8.66659 13.18 8.29992 12.82 7.75325L11.4667 8.20659C11.6 9.06659 11.36 9.97325 10.74 10.6666L11.68 11.8466C11.9333 11.7266 12.2067 11.6666 12.5 11.6666C13.6067 11.6666 14.5 12.5599 14.5 13.6666C14.5 14.7733 13.6067 15.6666 12.5 15.6666C11.3933 15.6666 10.5 14.7733 10.5 13.6666C10.5 13.2133 10.6467 12.7999 10.9 12.4666L9.96 11.2866C9.06 11.7866 7.95333 11.7933 7.04667 11.2866L6.1 12.4666Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1463_12400">
          <rect width="16" height="16" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Hub
