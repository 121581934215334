import { IconSVGProps } from '@/components/ui/icon'

function Ticket({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="currentcolor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.3335 8C13.3335 7.49333 13.6202 7.05333 14.0402 6.82666C14.4402 6.60666 14.6668 6.15333 14.6668 5.69333V4C14.6668 3.26666 14.0668 2.66666 13.3335 2.66666H2.66683C1.9335 2.66666 1.34016 3.26 1.34016 3.99333V5.69333C1.34016 6.15333 1.56016 6.60666 1.96683 6.82C2.38683 7.05333 2.66683 7.49333 2.66683 8C2.66683 8.50666 2.38016 8.95333 1.96016 9.17333C1.56016 9.39333 1.3335 9.84666 1.3335 10.3067V11.8067C1.3335 12.7333 1.9335 13.3333 2.66683 13.3333H13.3335C14.0668 13.3333 14.6668 12.7333 14.6668 12V10.3067C14.6668 9.84666 14.4402 9.39333 14.0402 9.17333C13.6202 8.94666 13.3335 8.50666 13.3335 8ZM9.66683 10.7333L8.00016 9.66666L6.3335 10.74C6.08016 10.9 5.7535 10.6667 5.8335 10.3733L6.3335 8.45333L4.80016 7.2C4.56683 7.00666 4.68683 6.62666 4.9935 6.60666L6.96683 6.49333L7.68683 4.66C7.80016 4.38 8.20016 4.38 8.30683 4.66L9.02683 6.5L11.0002 6.61333C11.3002 6.63333 11.4268 7.01333 11.1935 7.20666L9.66016 8.46L10.1668 10.3667C10.2468 10.6667 9.92016 10.9 9.66683 10.7333Z" />
    </svg>
  )
}

export default Ticket
