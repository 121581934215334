import { Menu } from '@/components/structure/sidebar/menu'
import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from '@/components/ui/sheet'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

export function SheetMenu() {
  return (
    <Sheet>
      <SheetTrigger className="lg:hidden" asChild>
        <Button className="h-8 rounded-full p-3" size="icon" variant="outline">
          <Icon name="menu" />
        </Button>
      </SheetTrigger>
      <SheetContent className="flex h-full flex-col px-3 sm:w-72" side="left">
        <SheetTitle>
          <VisuallyHidden.Root>Menu</VisuallyHidden.Root>
          <SheetDescription>
            <VisuallyHidden.Root>Description goes here</VisuallyHidden.Root>
          </SheetDescription>
        </SheetTitle>
        <SheetHeader>
          <div className="flex items-center justify-center">
            <a href="/" className="flex items-center gap-2">
              <Icon name="large-logo" />
            </a>
          </div>
        </SheetHeader>
        <Menu isOpen />
      </SheetContent>
    </Sheet>
  )
}
