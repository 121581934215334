import { create } from 'zustand'

import { AuthSlice, useAuthSlice } from './auth'
import { SidebarSlice, useSidebarSlice } from './sidebar'

type Store = SidebarSlice & AuthSlice

const useStore = create<Store>()((...params) => {
  return {
    ...useSidebarSlice(...params),
    ...useAuthSlice(...params)
  }
})

export { useStore }
