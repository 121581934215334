import { IconSVGProps } from '@/components/ui/icon'

function Login({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.58268 6.41667C8.25768 6.74167 8.25768 7.25833 8.58268 7.58333L10.166 9.16667H2.49935C2.04102 9.16667 1.66602 9.54167 1.66602 10C1.66602 10.4583 2.04102 10.8333 2.49935 10.8333H10.166L8.58268 12.4167C8.25768 12.7417 8.25768 13.2583 8.58268 13.5833C8.90768 13.9083 9.42435 13.9083 9.74935 13.5833L12.741 10.5917C13.066 10.2667 13.066 9.74167 12.741 9.41667L9.74935 6.41667C9.42435 6.09167 8.90768 6.09167 8.58268 6.41667ZM16.666 15.8333H10.8327C10.3743 15.8333 9.99935 16.2083 9.99935 16.6667C9.99935 17.125 10.3743 17.5 10.8327 17.5H16.666C17.5827 17.5 18.3327 16.75 18.3327 15.8333V4.16667C18.3327 3.25 17.5827 2.5 16.666 2.5H10.8327C10.3743 2.5 9.99935 2.875 9.99935 3.33333C9.99935 3.79167 10.3743 4.16667 10.8327 4.16667H16.666V15.8333Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Login
