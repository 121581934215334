import { IconSVGProps } from '@/components/ui/icon'

function Dns({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0833 7.58333H2.91667C2.275 7.58333 1.75 8.10833 1.75 8.75V11.0833C1.75 11.725 2.275 12.25 2.91667 12.25H11.0833C11.725 12.25 12.25 11.725 12.25 11.0833V8.75C12.25 8.10833 11.725 7.58333 11.0833 7.58333ZM4.08333 11.0833C3.44167 11.0833 2.91667 10.5583 2.91667 9.91667C2.91667 9.275 3.44167 8.75 4.08333 8.75C4.725 8.75 5.25 9.275 5.25 9.91667C5.25 10.5583 4.725 11.0833 4.08333 11.0833ZM11.0833 1.75H2.91667C2.275 1.75 1.75 2.275 1.75 2.91667V5.25C1.75 5.89167 2.275 6.41667 2.91667 6.41667H11.0833C11.725 6.41667 12.25 5.89167 12.25 5.25V2.91667C12.25 2.275 11.725 1.75 11.0833 1.75ZM4.08333 5.25C3.44167 5.25 2.91667 4.725 2.91667 4.08333C2.91667 3.44167 3.44167 2.91667 4.08333 2.91667C4.725 2.91667 5.25 3.44167 5.25 4.08333C5.25 4.725 4.725 5.25 4.08333 5.25Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Dns
