export const APP_ROUTES = {
  ROOT: '/',
  NOT_FOUND: '*',
  SIGN_IN: '/sign-in',
  USERS: '/users',
  CREATE_USER: '/users/create',
  EDIT_USER: '/users/edit',
  USER_PROFILE: '/profile',
  EVENTS: '/events',
  EVENT_REGISTRATION: '/events/:eventId/registration',
  EVENT_DETAILS: '/events/:eventId/details',
  CREATE_EVENT: '/events/create',
  EDIT_EVENT: '/events/edit',
  CREATE_EXTERNAL_USER: '/external-user/create',
  EXTERNAL_USER_DETAILS: '/external-user/details',
  PROJECTS: '/projects',
  CREATE_PROJECT: '/projects/create',
  EDIT_PROJECT: '/projects/edit',
  PROJECT_DETAILS: '/projects/details',
  PROJECTS_PROPOSALS: '/projects-proposals',
  CREATE_PROJECT_PROPOSAL: '/projects-proposals/create',
  EDIT_PROJECT_PROPOSAL: '/projects-proposals/edit',
  PROJECT_PROPOSAL_DETAILS: '/projects-proposals/details',
  INSTITUTIONS: '/institutions',
  CREATE_INSTITUTION: '/institutions/create',
  EDIT_INSTITUTION: '/institutions/edit',
  INSTITUTION_DETAILS: '/institutions/:institutionId/details'
}
