import { StateCreator } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { Locales } from '@/enums/locales'
import { queryClient } from '@/lib/react-query'
import { UserLogged } from '@/types/user-logged'
import { jwtDecodeUtil } from '@/utils/jwt.utils'

type State = {
  isAuthenticated: boolean
  accessToken: string | null
  refreshToken: string | null
  userLogged: UserLogged | null
  locale: Locales
}

type Actions = {
  login: (accessToken: string, refreshToken: string) => void
  logout: () => void
  changeLocale: (locale: Locales) => void
}

export type AuthSlice = State & Actions

const initialState: State = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  userLogged: null,
  locale: Locales.PT
}

const createAuthSlice: StateCreator<AuthSlice> = (set) => {
  return {
    ...initialState,
    login: (accessToken: string, refreshToken: string) => {
      const decoded = jwtDecodeUtil(accessToken)
      set({
        isAuthenticated: true,
        accessToken: accessToken,
        refreshToken: refreshToken,
        userLogged: decoded
      })
    },
    logout: () => {
      queryClient.clear()
      set({
        isAuthenticated: false,
        accessToken: null,
        userLogged: null,
        refreshToken: null
      })
    },
    changeLocale: (locale) => {
      set({ locale })
    }
  }
}

const useAuthSlice = persist(createAuthSlice, {
  name: 'auth',
  storage: createJSONStorage(() => localStorage)
})

export { useAuthSlice }
