import { IconSVGProps } from '@/components/ui/icon'

function AccessTime({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.4925 1.5C5.3525 1.5 2 4.86 2 9C2 13.14 5.3525 16.5 9.4925 16.5C13.64 16.5 17 13.14 17 9C17 4.86 13.64 1.5 9.4925 1.5ZM12.5 12C12.2075 12.2925 11.735 12.2925 11.4425 12L8.975 9.5325C8.8325 9.39 8.75 9.2025 8.75 9V6C8.75 5.5875 9.0875 5.25 9.5 5.25C9.9125 5.25 10.25 5.5875 10.25 6V8.6925L12.5 10.9425C12.7925 11.235 12.7925 11.7075 12.5 12Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AccessTime
