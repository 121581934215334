import { IconSVGProps } from '@/components/ui/icon'

function Person({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00008 8C9.47341 8 10.6667 6.80667 10.6667 5.33334C10.6667 3.86001 9.47341 2.66667 8.00008 2.66667C6.52675 2.66667 5.33341 3.86001 5.33341 5.33334C5.33341 6.80667 6.52675 8 8.00008 8ZM8.00008 9.33334C6.22008 9.33334 2.66675 10.2267 2.66675 12V12.6667C2.66675 13.0333 2.96675 13.3333 3.33341 13.3333H12.6667C13.0334 13.3333 13.3334 13.0333 13.3334 12.6667V12C13.3334 10.2267 9.78008 9.33334 8.00008 9.33334Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Person
