import { IconSVGProps } from '@/components/ui/icon'

function Globe({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90022 3.72016C4.25253 3.72016 3.72748 4.24521 3.72748 4.89289C3.72748 5.54058 4.25253 6.06563 4.90022 6.06563C5.5479 6.06563 6.07296 5.54058 6.07296 4.89289C6.07296 4.24521 5.5479 3.72016 4.90022 3.72016ZM2.3125 4.89289C2.3125 3.46373 3.47106 2.30518 4.90022 2.30518C6.32937 2.30518 7.48794 3.46373 7.48794 4.89289C7.48794 6.32205 6.32937 7.48061 4.90022 7.48061C3.47106 7.48061 2.3125 6.32205 2.3125 4.89289Z"
        fill="currentcolor"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.084 32.955C33.9125 32.955 32.9628 33.9047 32.9628 35.0762C32.9628 36.2477 33.9125 37.1974 35.084 37.1974C36.2555 37.1974 37.2052 36.2477 37.2052 35.0762C37.2052 33.9047 36.2555 32.955 35.084 32.955ZM31.5479 35.0762C31.5479 33.1232 33.131 31.54 35.084 31.54C37.037 31.54 38.6202 33.1232 38.6202 35.0762C38.6202 37.0292 37.037 38.6123 35.084 38.6123C33.131 38.6123 31.5479 37.0292 31.5479 35.0762Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9918 4.18015C11.2633 4.18015 4.18747 11.256 4.18747 19.9845C4.18747 28.713 11.2633 35.7889 19.9918 35.7889C28.7203 35.7889 35.7962 28.713 35.7962 19.9845C35.7962 11.256 28.7203 4.18015 19.9918 4.18015ZM3.71582 19.9845C3.71582 10.9955 11.0028 3.7085 19.9918 3.7085C28.9808 3.7085 36.2678 10.9955 36.2678 19.9845C36.2678 28.9735 28.9808 36.2605 19.9918 36.2605C11.0028 36.2605 3.71582 28.9735 3.71582 19.9845Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1998 8.75266C12.6854 11.6151 11.7417 15.5857 11.7417 19.9845C11.7417 24.3833 12.6854 28.3539 14.1998 31.2163C15.7197 34.0893 17.779 35.7889 19.9918 35.7889C22.2047 35.7889 24.2639 34.0893 25.7839 31.2163C27.2982 28.3539 28.242 24.3833 28.242 19.9845C28.242 15.5857 27.2982 11.6151 25.7839 8.75266C24.2639 5.87967 22.2047 4.18015 19.9918 4.18015C17.779 4.18015 15.7197 5.87967 14.1998 8.75266ZM13.7829 8.5321C15.3343 5.59969 17.518 3.7085 19.9918 3.7085C22.4657 3.7085 24.6494 5.59969 26.2008 8.5321C27.7578 11.4751 28.7136 15.5245 28.7136 19.9845C28.7136 24.4445 27.7578 28.4939 26.2008 31.4369C24.6494 34.3693 22.4657 36.2605 19.9918 36.2605C17.518 36.2605 15.3343 34.3693 13.7829 31.4369C12.2259 28.4939 11.27 24.4445 11.27 19.9845C11.27 15.5245 12.2259 11.4751 13.7829 8.5321Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7563 36.0247V3.94434H20.228V36.0247H19.7563Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.0154 20.2539H3.95166V19.7822H36.0154V20.2539Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43115 11.2461H33.6695V11.7177H6.43115V11.2461Z"
        fill="currentcolor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43115 28.2349H33.553V28.7065H6.43115V28.2349Z"
        fill="currentcolor"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.49805 15.9766H15.497V23.9922H7.49805V15.9766ZM9.85635 18.3349V21.6339H13.1387V18.3349H9.85635Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Globe
