import { IconSVGProps } from '@/components/ui/icon'

function VideoCam({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5007 8.75V5.83333C14.5007 5.375 14.1257 5 13.6673 5H3.66732C3.20898 5 2.83398 5.375 2.83398 5.83333V14.1667C2.83398 14.625 3.20898 15 3.66732 15H13.6673C14.1257 15 14.5007 14.625 14.5007 14.1667V11.25L16.409 13.1583C16.934 13.6833 17.834 13.3083 17.834 12.5667V7.425C17.834 6.68333 16.934 6.30833 16.409 6.83333L14.5007 8.75Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default VideoCam
