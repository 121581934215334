import { IconSVGProps } from '@/components/ui/icon'

function AttachMoney({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 1.5C5.36 1.5 2 4.86 2 9C2 13.14 5.36 16.5 9.5 16.5C13.64 16.5 17 13.14 17 9C17 4.86 13.64 1.5 9.5 1.5ZM10.5575 13.5675V14.0025C10.5575 14.55 10.1075 15 9.56 15H9.5525C9.005 15 8.555 14.55 8.555 14.0025V13.5525C7.5575 13.3425 6.6725 12.795 6.2975 11.8725C6.125 11.46 6.4475 11.0025 6.8975 11.0025H7.0775C7.355 11.0025 7.58 11.19 7.685 11.4525C7.9025 12.015 8.4725 12.405 9.5675 12.405C11.0375 12.405 11.3675 11.67 11.3675 11.2125C11.3675 10.59 11.0375 10.005 9.365 9.6075C7.505 9.1575 6.23 8.3925 6.23 6.855C6.23 5.565 7.2725 4.725 8.5625 4.4475V3.9975C8.5625 3.45 9.0125 3 9.56 3H9.5675C10.115 3 10.565 3.45 10.565 3.9975V4.4625C11.6 4.7175 12.2525 5.3625 12.5375 6.1575C12.6875 6.57 12.3725 7.005 11.93 7.005H11.735C11.4575 7.005 11.2325 6.81 11.1575 6.54C10.985 5.97 10.5125 5.6025 9.5675 5.6025C8.4425 5.6025 7.7675 6.1125 7.7675 6.8325C7.7675 7.4625 8.255 7.875 9.77 8.265C11.285 8.655 12.905 9.3075 12.905 11.1975C12.89 12.57 11.8625 13.32 10.5575 13.5675Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AttachMoney
