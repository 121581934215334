import { IconSVGProps } from '@/components/ui/icon'

function SpreadSheet({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 2.5H4.16667C3.24167 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8333V4.16667C17.5 3.25 16.7583 2.5 15.8333 2.5ZM15.8333 15.8333H4.16667V5.83333H15.8333V15.8333ZM13.3333 10H6.66667C6.20833 10 5.83333 9.625 5.83333 9.16667C5.83333 8.70833 6.20833 8.33333 6.66667 8.33333H13.3333C13.7917 8.33333 14.1667 8.70833 14.1667 9.16667C14.1667 9.625 13.7917 10 13.3333 10ZM10 13.3333H6.66667C6.20833 13.3333 5.83333 12.9583 5.83333 12.5C5.83333 12.0417 6.20833 11.6667 6.66667 11.6667H10C10.4583 11.6667 10.8333 12.0417 10.8333 12.5C10.8333 12.9583 10.4583 13.3333 10 13.3333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SpreadSheet
