import { IconSVGProps } from '@/components/ui/icon'

function Destination({ size = 16 }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.705 8.75C15.36 5.6225 12.8775 3.14 9.75 2.795V2C9.75 1.5875 9.4125 1.25 9 1.25C8.5875 1.25 8.25 1.5875 8.25 2V2.795C5.1225 3.14 2.64 5.6225 2.295 8.75H1.5C1.0875 8.75 0.75 9.0875 0.75 9.5C0.75 9.9125 1.0875 10.25 1.5 10.25H2.295C2.64 13.3775 5.1225 15.86 8.25 16.205V17C8.25 17.4125 8.5875 17.75 9 17.75C9.4125 17.75 9.75 17.4125 9.75 17V16.205C12.8775 15.86 15.36 13.3775 15.705 10.25H16.5C16.9125 10.25 17.25 9.9125 17.25 9.5C17.25 9.0875 16.9125 8.75 16.5 8.75H15.705ZM9 14.75C6.0975 14.75 3.75 12.4025 3.75 9.5C3.75 6.5975 6.0975 4.25 9 4.25C11.9025 4.25 14.25 6.5975 14.25 9.5C14.25 12.4025 11.9025 14.75 9 14.75Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Destination
