import { IconSVGProps } from '@/components/ui/icon'

function Delete({ size = 16 }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 20 20`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00008 15.8333C5.00008 16.75 5.75008 17.5 6.66675 17.5H13.3334C14.2501 17.5 15.0001 16.75 15.0001 15.8333V7.5C15.0001 6.58333 14.2501 5.83333 13.3334 5.83333H6.66675C5.75008 5.83333 5.00008 6.58333 5.00008 7.5V15.8333ZM15.0001 3.33333H12.9167L12.3251 2.74167C12.1751 2.59167 11.9584 2.5 11.7417 2.5H8.25841C8.04175 2.5 7.82508 2.59167 7.67508 2.74167L7.08341 3.33333H5.00008C4.54175 3.33333 4.16675 3.70833 4.16675 4.16667C4.16675 4.625 4.54175 5 5.00008 5H15.0001C15.4584 5 15.8334 4.625 15.8334 4.16667C15.8334 3.70833 15.4584 3.33333 15.0001 3.33333Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Delete
