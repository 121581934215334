import { useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { CollapseMenuButton } from '@/components/structure/sidebar/collapse-menu-button'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip'
import { APP_ROUTES } from '@/constants/app-routes'
import { cn } from '@/lib/utils'
import { useStore } from '@/store'
import { getFirstAndLastName } from '@/utils/get-first-last-string-name'
import { getInitials } from '@/utils/get-initials.utils'
import { getMenuList } from '@/utils/menu-list.utils'

interface MenuProps {
  isOpen: boolean | undefined
}

export function Menu({ isOpen }: Readonly<MenuProps>) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const menuList = getMenuList(pathname)

  const { logout, logged } = useStore((store) => {
    return { logout: store.logout, logged: store.userLogged }
  })

  const userLogged = useMemo(() => {
    if (logged) {
      return {
        sub: logged.sub,
        email: logged.email,
        initials: getInitials(logged.sub as string),
        id: logged.id
      }
    }
    return null
  }, [logged])

  const firstNameAndLastName = userLogged?.sub ? getFirstAndLastName(userLogged.sub) : ''

  function handleLogout() {
    logout()
    navigate(APP_ROUTES.SIGN_IN)
  }

  const handleSeeProfile = () => {
    navigate(`${APP_ROUTES.USER_PROFILE}/${userLogged?.id}`)
  }

  return (
    <ScrollArea className="[&>div>div[style]]:!block">
      <nav className="mt-8 size-full">
        <ul className="flex min-h-[calc(100vh-48px-36px-16px-32px)] flex-col items-start gap-4 space-y-1 px-2">
          {menuList.map(({ groupLabel, menus }, index) => (
            <li className="w-full" key={index}>
              {(isOpen && groupLabel) || isOpen === undefined ? (
                <p className="max-w-[248px] truncate px-4 pb-4 text-sm font-extrabold text-gray-600">
                  {groupLabel}
                </p>
              ) : !isOpen && isOpen !== undefined && groupLabel ? (
                <TooltipProvider>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger className="w-full">
                      <div className="flex w-full items-center justify-center">
                        <Icon name="more_horiz" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <p>{groupLabel}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <p className="pb-2"></p>
              )}
              {menus.map(({ href, label, icon, active, submenus }, index) =>
                submenus.length === 0 ? (
                  <div className="w-full" key={index}>
                    <TooltipProvider disableHoverableContent>
                      <Tooltip delayDuration={100}>
                        <TooltipTrigger asChild>
                          <Button
                            variant={active ? 'default' : 'ghost'}
                            className="mb-1 h-10 w-full justify-start border-none"
                            asChild
                          >
                            <Link
                              to={href}
                              className={cn(
                                isOpen ? '' : 'flex items-center justify-center'
                              )}
                            >
                              <Icon name={icon} className={cn(!isOpen ? '' : 'mr-4')} />
                              <p
                                className={cn(
                                  'max-w-[200px] truncate',
                                  !isOpen
                                    ? 'hidden -translate-x-96 opacity-0'
                                    : 'translate-x-0 opacity-100'
                                )}
                              >
                                {label}
                              </p>
                            </Link>
                          </Button>
                        </TooltipTrigger>
                        {isOpen === false && (
                          <TooltipContent side="right">{label}</TooltipContent>
                        )}
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                ) : (
                  <div className="w-full" key={index}>
                    <CollapseMenuButton
                      icon={icon}
                      label={label}
                      active={active}
                      submenus={submenus}
                      isOpen={isOpen}
                    />
                  </div>
                )
              )}
            </li>
          ))}
          <div className="flex size-full grow items-end">
            <ul className="flex w-full grow flex-col items-end gap-2">
              <li className="flex w-full grow items-end">
                <TooltipProvider disableHoverableContent>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger asChild>
                      <div
                        className={cn(
                          isOpen ? 'justify-self-start border' : '',
                          'flex w-full items-center gap-3 rounded-full p-2'
                        )}
                      >
                        <Avatar
                          className={cn(
                            'float-left size-8 bg-orange-900',
                            !isOpen && 'cursor-pointer'
                          )}
                          onClick={() => {
                            if (!isOpen) handleSeeProfile()
                          }}
                        >
                          <AvatarImage src="#" alt="Avatar" />
                          <AvatarFallback className="bg-transparent text-xs font-bold text-white">
                            {userLogged?.initials}
                          </AvatarFallback>
                        </Avatar>

                        {isOpen && (
                          <div
                            className={cn(
                              !isOpen && 'hidden justify-center',
                              'flex flex-col text-left'
                            )}
                          >
                            <p className="mt-1 truncate text-base font-extrabold leading-none">
                              {firstNameAndLastName}
                            </p>
                            <p className="leading-none text-muted-foreground">
                              <Button
                                variant="invisible"
                                className="mt-1 max-h-0.5 justify-center pl-0 text-xs font-bold text-orange-900"
                                onClick={handleSeeProfile}
                              >
                                <p
                                  className={cn(
                                    'whitespace-nowrap',
                                    !isOpen ? 'hidden opacity-0' : 'opacity-100'
                                  )}
                                >
                                  Ver perfil
                                </p>
                              </Button>
                            </p>
                          </div>
                        )}
                      </div>
                    </TooltipTrigger>
                    {!isOpen && (
                      <TooltipContent side="right">{userLogged?.sub}</TooltipContent>
                    )}
                  </Tooltip>
                </TooltipProvider>
              </li>
              <li className="flex w-full grow items-end">
                <TooltipProvider disableHoverableContent>
                  <Tooltip delayDuration={100}>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        className="float-left w-full justify-normal pl-3 text-left font-bold text-danger-400"
                        onClick={handleLogout}
                      >
                        <span className={cn(isOpen === false ? '' : 'mr-2')}>
                          <Icon name="logout" size={18} />
                        </span>
                        <p
                          className={cn(
                            'whitespace-nowrap',
                            !isOpen ? 'hidden opacity-0' : 'opacity-100'
                          )}
                        >
                          Sair
                        </p>
                      </Button>
                    </TooltipTrigger>
                    {!isOpen && <TooltipContent side="right">Sair</TooltipContent>}
                  </Tooltip>
                </TooltipProvider>
              </li>
            </ul>
          </div>
        </ul>
      </nav>
    </ScrollArea>
  )
}
