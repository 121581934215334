import { IconSVGProps } from '@/components/ui/icon'

function Contactless({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM5.10352 7.97984C5.02768 8.23067 4.74185 8.34734 4.50852 8.24234C4.31018 8.149 4.21102 7.92734 4.27518 7.71734C4.34518 7.47817 4.38018 7.23317 4.37435 6.994C4.36852 6.75484 4.33935 6.52734 4.27518 6.30567C4.21685 6.09567 4.31018 5.86817 4.50852 5.78067C4.75352 5.66984 5.03935 5.804 5.11518 6.0665C5.20268 6.364 5.24352 6.66734 5.24935 6.98234C5.24935 7.309 5.20268 7.64734 5.10352 7.97984ZM6.93518 8.90734C6.83602 9.1465 6.54435 9.23984 6.31685 9.1115C6.12435 9.00067 6.04852 8.76734 6.13018 8.56317C6.32268 8.114 6.41602 7.61234 6.41602 7.06984C6.41602 6.50984 6.31102 5.96734 6.10685 5.44817C6.02518 5.23817 6.11852 5.00484 6.31685 4.89984C6.54435 4.78317 6.82435 4.88817 6.91768 5.12734C7.16268 5.74567 7.28518 6.399 7.28518 7.06984C7.29685 7.729 7.17435 8.34734 6.93518 8.90734ZM8.74935 9.68317C8.65018 9.9165 8.37602 10.0215 8.15435 9.91067C7.95018 9.8115 7.85102 9.5665 7.93852 9.3565C8.28268 8.54567 8.45768 7.75234 8.45768 6.98817C8.45768 6.224 8.28268 5.44234 7.94435 4.649C7.85102 4.439 7.95018 4.194 8.15435 4.09484C8.38185 3.97817 8.65018 4.08317 8.74935 4.3165C9.13435 5.21484 9.33268 6.11317 9.33268 6.98817C9.33268 7.86317 9.13435 8.77317 8.74935 9.68317Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default Contactless
