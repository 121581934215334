import { IconSVGProps } from '@/components/ui/icon'

function KeyboardArrowUp({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.09395 11.2499L9.00395 8.33989L11.9139 11.2499C12.2064 11.5424 12.6789 11.5424 12.9714 11.2499C13.2639 10.9574 13.2639 10.4849 12.9714 10.1924L9.52895 6.74989C9.23645 6.45739 8.76395 6.45739 8.47145 6.74989L5.02895 10.1924C4.73645 10.4849 4.73645 10.9574 5.02895 11.2499C5.32145 11.5349 5.80145 11.5424 6.09395 11.2499Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default KeyboardArrowUp
