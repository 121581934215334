import { IconSVGProps } from '@/components/ui/icon'

function AttachEmail({ size }: IconSVGProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 7.5V3C15.75 2.175 15.075 1.5 14.25 1.5H2.25C1.425 1.5 0.7575 2.175 0.7575 3L0.75 12C0.75 12.825 1.425 13.5 2.25 13.5H10.5V9.75C10.5 8.505 11.505 7.5 12.75 7.5H15.75ZM8.6475 8.0025C8.4075 8.1525 8.0925 8.1525 7.8525 8.0025L2.55 4.6875C2.3625 4.5675 2.25 4.365 2.25 4.1475C2.25 3.645 2.7975 3.345 3.225 3.6075L8.25 6.75L13.275 3.6075C13.7025 3.345 14.25 3.645 14.25 4.1475C14.25 4.365 14.1375 4.5675 13.95 4.6875L8.6475 8.0025Z"
        fill="currentcolor"
      />
      <path
        d="M16.5 10.5C16.0875 10.5 15.75 10.8375 15.75 11.25V13.5C15.75 14.325 15.075 15 14.25 15C13.425 15 12.75 14.325 12.75 13.5V10.125C12.75 9.915 12.915 9.75 13.125 9.75C13.335 9.75 13.5 9.915 13.5 10.125V12.75C13.5 13.1625 13.8375 13.5 14.25 13.5C14.6625 13.5 15 13.1625 15 12.75V10.125C15 9.09 14.16 8.25 13.125 8.25C12.09 8.25 11.25 9.09 11.25 10.125V13.5C11.25 15.1575 12.5925 16.5 14.25 16.5C15.9075 16.5 17.25 15.1575 17.25 13.5V11.25C17.25 10.8375 16.9125 10.5 16.5 10.5Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default AttachEmail
